<template>
    <MobileSideNavigation v-if="store.isMobileOrTabletScreenSize" />
    <transition v-else name="slide-in-out" appear>
        <DesktopSideNavigation v-show="!props.isLoginPage" class="primary-side-bar" @toggleSecondarySidebar="() => {}" />
    </transition>
</template>

<script setup lang="ts">
import { DesktopSideNavigation, MobileSideNavigation } from '@/modules/CORE/navigation'
import { useGlobalStore } from '@/stores/global.js'

const props = defineProps({
    isLoginPage: {
        type: Boolean,
        default: false,
    },
})

const store = useGlobalStore()
</script>

<style lang="scss">
@import '../../../scss/core.scss';

@import '@/scss/breakpoints.scss';

.slide-in-out-enter-active,
.slide-in-out-leave-active {
    transition: transform $slide-in-animation-duration;
}

.slide-in-out-enter-from {
    transform: translateX(-100%);
}
.slide-in-out-enter-to,
.slide-in-out-leave-from {
    transform: translateX(0);
}

.slide-in-out-leave-to {
    transform: translateX(-100%);
}
</style>
