import { api } from './ApiSettings'
import http from './http'

import type { ICompany } from '@/modules/CORE/company/interfaces/ICompany'
import type { IPaginatedResponse, UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { IFacilityListForNotifications } from '@/modules/CORE/facility/interfaces/IFacility'
import type { IUser } from '@/modules/CORE/interfaces/CoreTypes'
import type { ICompanyUser } from '@/modules/CORE/interfaces/IUser'
import type { AxiosResponse } from 'axios'

export default {
    fetchCompanyUsersPaginated: async (
        companyId: UUIDv4,
        page: number,
        pageSize: number,
        direction: string,
        sortBy: string,
        searchText: string,
    ): Promise<IPaginatedResponse | null> => {
        const fields = 'last_name,first_name,email'
        const url = api(
            `v1/users-admin-company/${companyId}/?search=${searchText}&page=${page}&page_size=${pageSize}&sort=${sortBy}&dir=${direction}&fields=${fields}`,
        )
        const errorMessage = 'Failed to get users'
        let response: Response | undefined
        let users: IPaginatedResponse | null = null
        try {
            response = await http.get(url)
            if (response.ok) {
                users = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return users
    },
    fetchUserNotifications: async (limit: number): Promise<any> => {
        const url = api(`v1/user-notification/all?limit=${limit}`)
        const errorMessage = 'Failed to get user notifications'
        let response: Response | undefined
        let notifications: any = null
        try {
            response = await http.get(url)
            if (response.ok) {
                notifications = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return notifications || []
    },
    markUserNotificationAsSeen: async (wsIds: string[]): Promise<any> => {
        const url = api('v1/user-notification/mark-seen/')
        const errorMessage = 'Failed to mark user notifications as seen'
        try {
            const response = await http.post(url, { ws_ids: wsIds })
            return response
        } catch (e) {
            console.error(errorMessage, e)
            throw new Error(errorMessage)
        }
    },
    markUserNotificationAsOpened: async (wsIds: string[]): Promise<any> => {
        const url = api('v1/user-notification/mark-opened/')
        const errorMessage = 'Failed to mark user notifications as opened'
        try {
            const response = await http.post(url, { ws_ids: wsIds })
            return response
        } catch (e) {
            console.error(errorMessage, e)
            throw new Error(errorMessage)
        }
    },
    getUnseenNotificationCountForFacility: async (facilityId: UUIDv4): Promise<number> => {
        const url = api(`v4/unseen-user-notification-count/${facilityId}/`)
        const errorMessage = 'Failed to get unseen notification count'
        let response: Response | undefined
        let count = 0
        try {
            response = await http.get(url)
            if (response.ok) {
                count = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }
        return count
    },
    getFacilityListForNotifications: async (companyId: UUIDv4): Promise<IFacilityListForNotifications[] | null> => {
        const url = api(`v4/facility-list-for-notifications/${companyId}/`)
        const errorMessage = 'Failed to get facility list for notifications'
        let response: Response | undefined
        let facilities: any = null
        try {
            response = await http.get(url)
            if (response.ok) {
                facilities = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return facilities
    },
    testEmailNotificationsEvent: async (): Promise<any> => {
        const url = api('v1/notifications/daily-engine-user-notifications/')
        const errorMessage = 'Failed to test email events event'
        let response: any

        try {
            response = await http.get(url)
            return response
        } catch (e) {
            console.error(errorMessage)
        }
    },
    fetchAllusersV2: async (): Promise<IUser[]> => {
        const url = api('v2/users/')
        const errorMessage = 'Failed to get all users'
        let response: Response | undefined

        try {
            response = await http.get(url)
            if (response.ok) {
                return await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
            return []
        }
    },
    clearRecentFacility: async (): Promise<boolean> => {
        const url = api('v4/clear-recent-facility')
        const errorMessage = 'Failed to clear recent facility'
        const response: Response | undefined = await http.get(url)
        if (response?.ok) {
            return true
        } else {
            console.error(errorMessage)
            return false
        }
    },
    changeUserName: async (params: { id: UUIDv4 }): Promise<boolean> => {
        const url = api(`v1/users/${params.id}/change_name/`)
        const errorMessage = 'POST request failed'
        let response: AxiosResponse | undefined
        try {
            response = await http.post(url, JSON.stringify(params))
            return response?.status == 200
        } catch (e) {
            console.error(errorMessage)
            return false
        }
    },
    setPasswordForUser: async (params: any, user_id: UUIDv4): Promise<boolean> => {
        const url = api(`v1/users/${user_id}/set_password/`)
        const errorMessage = 'Failed to set password'
        let response: AxiosResponse | undefined
        try {
            response = await http.post(url, JSON.stringify(params))
            return response?.status == 204
        } catch (e) {
            console.error(errorMessage)
            return false
        }
    },
    setUserActive: async ({ userId, is_active }: { userId: UUIDv4; is_active: boolean }): Promise<boolean> => {
        const url = api(`v1/users/${userId}/active/`)
        const errorMessage = 'Could not set is_active on user'
        let response: AxiosResponse | undefined
        try {
            response = await http.post(url, is_active)
            return response?.status == 200
        } catch (e) {
            console.error(errorMessage)
            return false
        }
    },
    setUserSuperuser: async ({ userId, is_superuser }: { userId: UUIDv4; is_superuser: boolean }): Promise<boolean> => {
        const url = api(`v1/users/${userId}/superuser/`)
        const errorMessage = 'Could not set is_superuser on user'
        let response: AxiosResponse | undefined
        try {
            response = await http.post(url, is_superuser)
            return response?.status == 200
        } catch (e) {
            console.error(errorMessage)
            return false
        }
    },
    fetchUserDetails: async (userId: UUIDv4): Promise<IUser | null> => {
        const url = api(`v1/users/${userId}`)
        const errorMessage = 'Failed to fetch user details.'
        try {
            const response = await http.get(url)
            if (response.ok) {
                return response.json()
            }
            console.error(errorMessage)
            return null
        } catch (e) {
            console.error(errorMessage)
            return null
        }
    },
    updateUserProfile: async (user: any) => {
        const url = api(`v1/user/profile/${user.profile_id}/`)
        const errorMessage = `Failed to update profile ${user.id}.`
        let response = null
        try {
            response = await http.put(url, JSON.stringify({ id: user.profile_id, user: user.id, data: user.data }))
        } catch (e) {
            console.error(errorMessage)
        }

        return response
    },
    // remove groups UPDATE THIS FOR FUCK'S SAKE AND NEVER USE THIS
    removeGroupFromUser: async (group: string[], userId: UUIDv4, company: ICompany) => {
        const url = api(`v1/users/${userId}/remove_group/`)
        const errorMessage = 'Failed to remove group from user'
        const body = company && company.id ? { group, company_id: company.id } : { group }
        let response = null
        try {
            response = await http.post(url, JSON.stringify(body))
        } catch (e) {
            console.error(errorMessage)
        }

        return { response }
    },
    // set groups UPDATE THIS FOR FUCK'S SAKE AND NEVER USE THIS
    setGroupForUser: async (group: string[], userId: UUIDv4, company: ICompany) => {
        const url = api(`v1/users/${userId}/set_group/`)
        const errorMessage = 'Failed to set group for user'
        const body = company && company.id ? { group, company_id: company.id } : { group }
        let response = null
        try {
            response = await http.post(url, JSON.stringify(body))
        } catch (e) {
            console.error(errorMessage)
        }

        return { response }
    },
    updateUserName: async (userId: UUIDv4, firstName: string, lastName: string) => {
        const url = api('v4/user/update-name')
        const errorMessage = 'Failed to update user'
        let response = null
        try {
            response = await http.patch(url, JSON.stringify({ user_id: userId, first_name: firstName, last_name: lastName }))
        } catch (err) {
            console.error(errorMessage, err)
        }

        return response
    },
    removeUserFromCompany: async (companyId: UUIDv4, userId: UUIDv4) => {
        const url = api(`v1/companies/${companyId}/remove-user/`)
        let response = null
        try {
            response = await http.post(url, JSON.stringify({ user: userId }))
        } catch (e) {
            console.error(e)
        }

        return response
    },
    getUserByEmail: async (user_email: string): Promise<ICompanyUser | null> => {
        const url = api('v4/user-by-email/')
        const errorMessage = 'Failed to get user by email'
        let response: AxiosResponse | undefined
        let user: ICompanyUser | null = null
        try {
            // POST here, so we can send the email in the body and not in the URL
            response = await http.post(url, JSON.stringify({ user_email }))
            if (response) {
                user = response.data
            }
        } catch (e) {
            console.error(errorMessage, e)
        }
        return user
    },
    resetUserNotificationCounter: async (): Promise<any> => {
        const url = api(`v1/user-notification/`)
        const errorMessage = 'Failed to get user notifications'
        let response: Response | undefined
        let notifications: any = null
        try {
            response = await http.get(url)
            if (response.ok) {
                notifications = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return notifications || []
    },
}
