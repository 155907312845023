import { api } from './ApiSettings'
import http from './http'

import type { APIResponse } from './ApiSettings'
import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { ICrewMember } from '@/modules/CORE/facility/interfaces/IFacility'
import type { AxiosResponse } from 'axios'

export default {
    fetchCrewByFacilityId: async (facilityId: string): Promise<ICrewMember[] | undefined> => {
        const url = api(`v1/facilities/${facilityId}/crew/`)
        const errorMessage = `Failed to get crew from facility ${facilityId}.`
        let response: Response | undefined
        let crew: ICrewMember[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                crew = await response.json()
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return crew
    },
    checkInCrewMember: async (facilityId: string, crewMemberId: UUIDv4, role: string): Promise<APIResponse<any>> => {
        const url = api('v1/crew/')
        const errorMessage = `Error: Failed to check in crew member ${crewMemberId} at facility ${facilityId}.`

        try {
            const response: AxiosResponse | undefined = await http.post(url, {
                facility: facilityId,
                user: crewMemberId,
                role,
            }) // Pass an empty object as the second argument

            if (response?.status === 201 || response?.status === 200) {
                const data = await response.data
                return { success: true, message: 'crew checked in', data }
            } else {
                return { success: false, message: 'failed to check in crew ', data: null }
            }
        } catch (e) {
            console.error(errorMessage)
            return { success: false, message: 'failed to check in crew ', data: null }
        }
    },
    checkOutCrewMember: async (crewMemberId: string): Promise<APIResponse<any>> => {
        const url = api(`v1/crew/${crewMemberId}/`)

        const response: AxiosResponse | undefined = await http.delete(url)

        if (response?.status === 200) {
            return { success: true, message: `crew member ${crewMemberId} checked out`, data: null }
        } else {
            return { success: false, message: `Failed to check out crew member ${crewMemberId}`, data: null }
        }
    },
}
