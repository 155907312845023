import {
    attachmentCount,
    getMondayValuesWithRegex,
    mapColumnValuesByFieldname,
    mapPublicCommentsFromClaim,
    translateMondayJSON,
} from '../utils'

import type {
    IOrderingPartDetails,
    IOrderingPartListElement,
    IOrderingPartPendingListElement,
    IOrderingPartStatus,
} from '../../interfaces/ISupport'

// This file contains the mapping of different needed versions of the OrderParts type
export default {
    mapPendingOrderParts: (orderPartItems: any): IOrderingPartPendingListElement[] => {
        const orderingPartsList: IOrderingPartPendingListElement[] = []

        for (let i = 0; i < orderPartItems.length; i++) {
            const orderingPart = {} as IOrderingPartPendingListElement
            orderingPart.id = orderPartItems[i].id
            orderingPart.comment_count_public = mapPublicCommentsFromClaim(orderPartItems[i].updates)
            orderingPart.name = orderPartItems[i].name
            orderingPart.date = mapColumnValuesByFieldname('Date', orderPartItems[i]) as string
            orderingPart.moen_contact = mapColumnValuesByFieldname('Person', orderPartItems[i]) as string
            orderingPart.customer_contact = mapColumnValuesByFieldname('Referanse kunde', orderPartItems[i]) as string
            orderingPart.delivery_address = mapColumnValuesByFieldname('Leveringsadresse', orderPartItems[i]) as string
            orderingPart.status = mapColumnValuesByFieldname('Status', orderPartItems[i]) as IOrderingPartStatus
            orderingPart.attachments_count = attachmentCount(orderPartItems[i])
            orderingPart.updated_at = orderPartItems[i].updated_at

            orderingPartsList.push(orderingPart)
        }
        return orderingPartsList
    },
    mapNotPendingOrderParts: (orderPartItems: any): IOrderingPartListElement[] => {
        const orderingPartsList: IOrderingPartListElement[] = []

        for (let i = 0; i < orderPartItems.length; i++) {
            const orderingPart = {} as IOrderingPartListElement
            orderingPart.customer_contact = mapColumnValuesByFieldname('Referanse kunde', orderPartItems[i]) as string
            orderingPart.date = mapColumnValuesByFieldname('Date', orderPartItems[i]) as string
            orderingPart.delivery_address = mapColumnValuesByFieldname('Leveringsadresse', orderPartItems[i]) as string
            orderingPart.id = orderPartItems[i].id
            orderingPart.moen_contact = mapColumnValuesByFieldname('Person', orderPartItems[i]) as string
            orderingPart.name = orderPartItems[i].name
            orderingPart.status = mapColumnValuesByFieldname('Status', orderPartItems[i]) as IOrderingPartStatus
            orderingPart.updated_at = orderPartItems[i].updated_at

            orderingPartsList.push(orderingPart)
        }
        return orderingPartsList
    },
    mapDetailsOrderParts: (data: any): IOrderingPartDetails => {
        const orderingPartDetails = {} as IOrderingPartDetails
        orderingPartDetails.ap_number = mapColumnValuesByFieldname('AP#', data) as string
        orderingPartDetails.arbeid_nok = mapColumnValuesByFieldname('Arbeid, reise og kost [NOK eks mva]', data) as string
        orderingPartDetails.admin_nok = mapColumnValuesByFieldname('Administrasjon[NOK eks mva]', data) as string
        orderingPartDetails.attachments = []
        orderingPartDetails.created_at = data.created_at
        orderingPartDetails.date = mapColumnValuesByFieldname('Date', data) as string
        orderingPartDetails.deler_utstyr_nok = mapColumnValuesByFieldname('Deler/utstyr/kurs [NOK eks mva og frakt]', data) as string
        orderingPartDetails.description =
            data.updates.length > 0
                ? (getMondayValuesWithRegex(data.updates[data.updates.length - 1].body, 'orderPartsDescription') as string)
                : ''
        orderingPartDetails.fakturaadresse = mapColumnValuesByFieldname('Fakturaadresse', data) as string
        orderingPartDetails.fakturert = translateMondayJSON('Fakturert', data, 'checked') as boolean
        orderingPartDetails.fasilitet = mapColumnValuesByFieldname('Fasilitet', data) as string
        orderingPartDetails.frakt_nok = mapColumnValuesByFieldname('Frakt [NOK eks mva]', data) as string
        orderingPartDetails.id = data.id
        orderingPartDetails.kunde = mapColumnValuesByFieldname('Kunde', data) as string
        orderingPartDetails.leverandor = mapColumnValuesByFieldname('Leverandør', data) as string
        orderingPartDetails.leveringsadresse = mapColumnValuesByFieldname('Leveringsadresse', data) as string
        orderingPartDetails.order_confirmation = translateMondayJSON('Order confirmation', data, 'checked') as boolean
        orderingPartDetails.order_confirmation_time = translateMondayJSON('Order confirmation', data, 'changed_at') as string
        orderingPartDetails.person = mapColumnValuesByFieldname('Person', data) as string
        orderingPartDetails.referanse_kunde = mapColumnValuesByFieldname('Referanse kunde', data) as string
        orderingPartDetails.sendt = translateMondayJSON('Sendt', data, 'checked') as boolean
        orderingPartDetails.sporingsnummer = mapColumnValuesByFieldname('Sporingsnummer', data) as string
        orderingPartDetails.status = mapColumnValuesByFieldname('Status', data) as IOrderingPartStatus
        orderingPartDetails.tilbud_kunde_godtatt = translateMondayJSON('Tilbud kunde godtatt', data, 'checked') as boolean
        orderingPartDetails.tilbud_kunde_sendt = translateMondayJSON('Tilbud kunde sendt', data, 'checked') as boolean
        orderingPartDetails.tilbud_leverandor_mottatt = translateMondayJSON('Tilbud leverandør mottatt', data, 'checked') as boolean
        orderingPartDetails.title = data.name
        orderingPartDetails.updated_at = data.updated_at
        return orderingPartDetails
    },
}
