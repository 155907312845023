<template>
    <div id="menu-search" tabindex="0" role="button" @click="show = !show">
        <MCIcon icon="search_gray-darkest" :size="25" />
    </div>
</template>

<script setup lang="ts">
const show = defineModel({ default: false })
</script>

<style lang="scss">
@import '@/scss/colors.scss';

#menu-search {
    width: 3.875rem;
    border-radius: 0.3125rem;
    background: $navigation-gray;
    padding-top: 0.0625rem;
    padding-bottom: 0.0625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: $interactive-lightest;
    }
    &:active {
        background: $interactive-lighter;
    }
}
</style>
