type Path = string | number

export const host = import.meta.env.VITE_APP_API_HOST as string
export const base = import.meta.env.VITE_APP_API_BASE as string

export interface APIResponse<T> {
    success: boolean
    message: string
    data: T | null
}

export const url = (path: Path): string => {
    return `${host}/${path}`
}

export const api = (path: Path): string => {
    if (base.length > 0) {
        return [host, base, path].join('/')
    }
    return `${host}/${path}`
}

export default {
    api,
    base,
    host,
    url,
}
