<template>
    <div class="empty-state-container" :title="hoverText" :aria-label="hoverText">
        <img :src="getRandomEmptyStateImage()" alt="empty state image" />
        <p class="subtext">
            {{ title }}
        </p>
        <p class="alert-text">
            {{ subtitle }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    hoverText: {
        type: String,
        default: '',
    },
})

const emptyStateImages = [
    'empty-state-crabby.svg', // Crab
    'empty-state-salamando.svg', // Axolotl
    'empty-state-jellybear.svg', // Jellyfish with ears
    'empty-state-sharky.svg', // Shark
    'empty-state-jellybean.svg', // Pink jellyfish
    'empty-state-squidlord.svg', // Pink squid
    'empty-state-puffy.svg', // Pufferfish
]

const title = ref(props.title)
const subtitle = ref(props.subtitle)

const getRandomEmptyStateImage = () => {
    const randomIndex = Math.floor(Math.random() * emptyStateImages.length)
    return `/${emptyStateImages[randomIndex]}`
}
</script>

<style scoped lang="scss">
.empty-state-container {
    text-align: center;
    width: 100%;
    img {
        margin-top: 1.125rem;
    }
}

.subtext {
    margin-top: 1.125rem;

    color: #545962;

    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.0022rem;
}

.alert-text {
    margin-top: 0.25rem;

    color: #545962;

    /* body/body */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
img {
    width: 7rem;
}
</style>
