import type { IPriority } from '@/modules/CORE/company/interfaces/IGeneric'

import { i18n } from '@/i18n.js'

// @ts-expect-error-next-line
const t = i18n.global.t

// Priority
export const getPriorityIcon = (priority: IPriority): string => {
    switch (priority) {
        case 'high':
            return 'arrow-up-filled_danger-main'
        case 'medium':
            return 'minus-filled_warning-main'
        case 'low':
            return 'arrow-down-filled_info-main'
    }
}

export const priorityText = (priority: IPriority): string => {
    switch (priority) {
        case 'high':
            return t('workorder.priority.high')
        case 'medium':
            return t('workorder.priority.medium')
        case 'low':
            return t('workorder.priority.low')
        default:
            return 'Unknown Priority'
    }
}

export const cardColor = (priority: IPriority) => {
    switch (priority) {
        case 'high':
            return '#d9534f' // $danger-main
        case 'medium':
            return '#f0ad4e' // $warning-main
        case 'low':
            return '#5bc0de' // $info-main
        default:
            return '#777' // $border-gray
    }
}
