interface Message {
    [key: string]: any
}

export enum eventType {
    UserNotification = 'user-notification',
    PushNotification = 'push-notification',
}

class WebSocketService {
    private socket: WebSocket | null = null
    private userNotificationlisteners: Array<(data: Message) => void> = []
    private pushNotificationlisteners: Array<(data: Message) => void> = []
    private userId: string
    private reconnectAttempts: number = 0
    private maxReconnectAttempts: number = 5
    private reconnectDelay: number = 1000 // 1 second initial delay

    constructor(userId: string) {
        this.userId = userId
        this.connect()

        window.addEventListener('beforeunload', () => {
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                this.socket.close(1000, 'Page refresh') // 1000 is a normal closure
            }
        })
    }

    private connect() {
        // Close existing WebSocket connection if open
        if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
            this.close()
        }

        this.socket = new WebSocket(this.getWebsocketURL())
        this.socket.onopen = this.onOpen
        this.socket.onmessage = this.onMessage
        this.socket.onerror = this.onError
        this.socket.onclose = this.onClose
    }

    private onOpen = () => {
        this.reconnectAttempts = 0
        this.socket?.send(`connect:${this.userId}`)
    }

    private onMessage = (event: MessageEvent) => {
        if (event.data === 'connected') return

        const data: Message = JSON.parse(event.data)
        console.warn('data.ws_event: ', data.ws_event)

        if (data.ws_event == eventType.UserNotification) {
            this.userNotificationlisteners.forEach((listener) => listener(data))
        } else if (data.ws_event == eventType.PushNotification) {
            this.pushNotificationlisteners.forEach((listener) => listener(data))
        }
    }

    private onError = (event: Event) => {
        console.warn(`Websocket Error${event}`)
        if (window.location.hostname === 'localhost') {
            // Log a warning instead of an error for local development
            // console.warn(
            //     'Not able to connect to local websocket server on localhost.Please run this environment if you need websocket functionality .',
            //     event,
            // )
        } else {
            // For non-localhost environments, log the error as usual
            console.error('WebSocket Error ', event)
        }
    }

    private onClose = () => {
        this.attemptReconnect()
    }

    private attemptReconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            setTimeout(() => {
                this.connect() // Attempt to reconnect
                this.reconnectAttempts++
                this.reconnectDelay *= 2 // Exponential backoff
            }, this.reconnectDelay)
        } else {
            console.info('Max reconnect attempts reached. Giving up.')
        }
    }

    public sendMessage = (message: Message) => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(message))
        } else {
            // console.error('WebSocket is not connected.')
        }
    }

    public addListener(listener: (data: Message) => void, type: eventType) {
        switch (type) {
            case eventType.UserNotification:
                this.userNotificationlisteners.push(listener)
                break
            case eventType.PushNotification:
                this.pushNotificationlisteners.push(listener)
                break
        }
    }

    public removeListener(listener: (data: Message) => void) {
        const index = this.userNotificationlisteners.indexOf(listener)
        if (index > -1) {
            this.userNotificationlisteners.splice(index, 1)
        } else {
            const index = this.pushNotificationlisteners.indexOf(listener)
            if (index > -1) {
                this.pushNotificationlisteners.splice(index, 1)
            }
        }
    }

    public close() {
        if (this.socket) {
            // Remove event listeners
            this.socket.onopen = null
            this.socket.onmessage = null
            this.socket.onerror = null
            this.socket.onclose = null

            // Close the WebSocket connection
            this.socket.close()
            this.socket = null

            // Reset reconnect attempts and delay to initial values
            this.reconnectAttempts = 0
            this.reconnectDelay = 1000
        }
    }

    private getWebsocketURL() {
        if (window.location.origin.includes('dev.')) {
            return 'wss://mlink-ws-26m4n4kf6a-ey.a.run.app/ws'
        } else if (window.location.origin.includes('staging.')) {
            return 'wss://mlink-ws-dga4rj6oeq-ey.a.run.app/ws'
        } else if (window.location.origin.includes('https://m-link.no')) {
            return 'wss://mlink-ws-o4f2ylpo6a-ey.a.run.app/ws'
        } else if (window.location.origin.includes('localhost')) {
            return 'ws://localhost:5000/ws'
        } else {
            return 'wss://mlink-ws-o4f2ylpo6a-ey.a.run.app/ws'
        }
    }
}

export default WebSocketService
