import axios from 'axios'
import { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios'

import csrfToken from './csrf'

type TUrl = string
interface IHeaders {
    [key: string]: string
}

const defaultHeaders: IHeaders = { 'Content-Type': 'application/json' }

const post = async (url: TUrl, data: any, headers = defaultHeaders, credentials: string = 'include'): Promise<AxiosResponse> => {
    const axiosConfig: AxiosRequestConfig = {
        headers: {
            ...headers,
            'X-CSRFToken': csrfToken(),
        },
        withCredentials: credentials === 'include',
    }

    try {
        const response = await axios.post(url, data, axiosConfig)
        return response
    } catch (error) {
        if (error instanceof AxiosError) {
            // Handle specific 401 Unauthorized error
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - 401: ', error.response.data)
                // redirecting user to logout page
                window.location.href = '/logout'
            } else if (error.response && error.response.status === 404) {
                // Silent handle of 404 error
            } else {
                console.error('AxiosError: ', error)
            }
        }
        throw error
    }
}

const postWithFetch = async (url: TUrl, data: any): Promise<Response> => {
    // Set the headers with Content-Type and X-CSRFToken
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken()!, // The exclamation mark is used to assert that csrfToken() will never return null or undefined
    }
    const request = new Request(url, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: JSON.stringify(data),
    })

    return fetch(request)
}

const filePost = async (url: TUrl, data: any, headers = {}, credentials: string = 'include'): Promise<AxiosResponse> => {
    const axiosConfig: AxiosRequestConfig = {
        headers: {
            ...headers,
            'X-CSRFToken': csrfToken(),
        },
        withCredentials: credentials === 'include',
    }

    try {
        const response = await axios.post(url, data, axiosConfig)
        return response
    } catch (error: any) {
        throw new Error(error.response?.data || error.message)
    }
}
const get = async (
    url: TUrl,
    headers: IHeaders = defaultHeaders,
    credentials: 'include' | 'omit' | 'same-origin' = 'include',
): Promise<Response> => {
    const request = new Request(url, {
        method: 'GET',
        credentials,
        headers: new Headers(headers),
    })
    // * convert to axios request
    return fetch(request)
}

const put = async (
    url: TUrl,
    data: any,
    headers: IHeaders = defaultHeaders,
    credentials: 'include' | 'omit' | 'same-origin' = 'include',
): Promise<AxiosResponse> => {
    const axiosConfig: AxiosRequestConfig = {
        headers: {
            ...headers,
            'X-CSRFToken': csrfToken(),
        },
        withCredentials: credentials === 'include',
    }
    try {
        const response = await axios.put(url, data, axiosConfig)
        return response
    } catch (error: any) {
        throw new Error(error.response?.data || error.message)
    }
}

const patch = async (
    url: TUrl,
    data: any,
    headers: IHeaders = defaultHeaders,
    credentials: 'include' | 'omit' | 'same-origin' = 'include',
): Promise<AxiosResponse> => {
    const axiosConfig: AxiosRequestConfig = {
        headers: {
            ...headers,
            'X-CSRFToken': csrfToken(),
        },
        withCredentials: credentials === 'include',
    }
    try {
        const response = await axios.patch(url, data, axiosConfig)
        return response
    } catch (error: any) {
        const errorData = error.response?.data
        if (errorData) {
            throw new Error(
                typeof errorData === 'string' ? errorData : `An error occurred during PATCH request, object: ${JSON.stringify(errorData)}`,
            )
        } else {
            throw new Error(error.message)
        }
    }
}

// * it is called _delete because delete is a reserved word
const _delete = async (url: TUrl, headers = defaultHeaders, credentials: string = 'include'): Promise<AxiosResponse> => {
    const axiosConfig: AxiosRequestConfig = {
        headers: {
            ...headers,
            'X-CSRFToken': csrfToken(),
        },
        withCredentials: credentials === 'include',
    }
    try {
        const response = await axios.delete(url, axiosConfig)
        return response
    } catch (error: any) {
        throw new Error(error.response?.data || error.message)
    }
}

export default {
    delete: _delete,
    filePost,
    get,
    patch,
    post,
    postWithFetch,
    put,
}
