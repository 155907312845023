import { api } from './ApiSettings'
import http from './http'

import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type {
    ICompanyContextPayload,
    IContextResponseData,
    IFacilityContextPayload,
    IResetPasswordForm,
    IUserProfile,
} from '@/modules/CORE/interfaces/CoreTypes'
import type { IInvite, IUser, IUserCreate } from '@/modules/CORE/interfaces/IUser'
import type { AxiosResponse } from 'axios'
import type { IMCDropdownSingleOption } from 'moen-components'
import type { IMCDropdownMultipleOption } from 'moen-components/dist/dropdowns/IMCDropdownMultiple'

const changePassword = async (new_password1: string, new_password2: string): Promise<AxiosResponse> => {
    const url = api('auth/password/change/')
    return await http.post(url, { new_password1, new_password2 })
}

const login = async (email: string, password: string): Promise<AxiosResponse> => {
    const url = api('auth/login/')
    return await http.post(url, { email, password })
}

const logout = async () => {
    const url = api('auth/logout/')
    const errorMessage = 'Failed to logout'
    let response = null
    try {
        response = await http.post(url, {})
    } catch (e) {
        console.warn(errorMessage, e)
    }

    return response
}

const noContext = async (): Promise<IContextResponseData | undefined> => {
    const url = api('v4/no-context/')

    const response = await http.post(url, {})

    if (response?.status === 200) {
        return response.data as IContextResponseData
    } else {
        return undefined
    }
}

const companyContext = async (payload: ICompanyContextPayload): Promise<IContextResponseData | undefined> => {
    const url = api('v4/company-context/')

    const response = await http.post(url, JSON.stringify(payload))

    if (response?.status === 200) {
        return response.data as IContextResponseData
    } else {
        return undefined
    }
}

const facilityContext = async (payload: IFacilityContextPayload): Promise<IContextResponseData | undefined> => {
    const url = api('v4/facility-context/')

    const response = await http.post(url, JSON.stringify(payload))

    if (response?.status === 200) {
        return response.data as IContextResponseData
    } else {
        return undefined
    }
}

const fetchUserIfAuthenticated = async (): Promise<IUser | null> => {
    const url = api('auth/user/')

    const response = await http.get(url)

    if (response?.ok) {
        const user: IUser = await response.json()
        return user
    } else {
        return null
    }
}

const fetchUserProfileById = async (profileId: number): Promise<IUserProfile | null> => {
    if (!profileId) {
        return null
    }

    const url = api(`v1/user/profile/${profileId}`)

    const response = await http.get(url)

    if (response?.ok) {
        const userProfile: IUserProfile = await response.json()
        return userProfile
    } else {
        return null
    }
}

const updateUserPartial = async (user: any): Promise<boolean> => {
    const url = api('auth/user/')

    const response = await http.patch(url, JSON.stringify(user))

    if (response?.status === 200) {
        return true
    }
    return false
}

const updateUser = async (user: IUserProfile): Promise<boolean> => {
    const url = api('auth/user/')

    const response = await http.put(url, JSON.stringify(user))

    if (response?.status === 200) {
        return true
    }
    return false
}

const fetchUsersMoenMarin = async (): Promise<IMCDropdownSingleOption[]> => {
    const url = api('v1/users-moenmarin/')

    const response = await http.get(url)

    if (response?.status === 200) {
        const users: IMCDropdownSingleOption[] = await response.json()
        return users
    } else {
        return []
    }
}

const createUser = async (token: string, user: IUserCreate, companyId: UUIDv4, groupId: number): Promise<AxiosResponse> => {
    const url = api('v4/accept-invitation/')
    return await http.post(url, JSON.stringify({ token, ...user, company_id: companyId, group_id: groupId }))
}

const fetchUsersInCompanyDropdownFormat = async (companyId: UUIDv4): Promise<IMCDropdownSingleOption[] | IMCDropdownMultipleOption> => {
    const url = api(`v1/users-company/${companyId}`)

    const response = await http.get(url)

    if (response?.status === 200) {
        const users: IMCDropdownSingleOption[] = await response.json()
        return users
    } else {
        return []
    }
}

const fetchInvite = async (inviteId: UUIDv4): Promise<IInvite> => {
    const url = api(`v4/fetch-invite/${inviteId}`)
    try {
        const response = await http.get(url)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        return (await response.json()) as IInvite
    } catch (error) {
        console.error('Failed to fetch invite:', error)
        throw error
    }
}

const deleteInvite = async (inviteId: UUIDv4): Promise<AxiosResponse> => {
    const url = api(`v4/delete-invite/${inviteId}`)
    return await http.delete(url)
}

const editInvite = async (inviteId: UUIDv4, groupId: string, companyId: UUIDv4): Promise<AxiosResponse> => {
    const url = api(`v4/edit-invite/${inviteId}`)
    return await http.patch(url, JSON.stringify({ company_id: companyId, group_id: groupId }))
}

const validateInviteKey = async (key: string): Promise<AxiosResponse> => {
    const url = api('v1/validate-invitation-token/')
    return await http.post(url, { invite_key: key })
}

const resetPassword = async (email: string): Promise<AxiosResponse> => {
    const url = api('auth/password/reset/')
    const payload = JSON.stringify({ email })
    return await http.post(url, payload)
}

const forgotPassword = async (form: IResetPasswordForm): Promise<AxiosResponse> => {
    const url = api('auth/password/reset/confirm/')
    const resetPasswordForm = new FormData()
    resetPasswordForm.append('uid', form.uid)
    resetPasswordForm.append('token', form.token)
    resetPasswordForm.append('new_password1', form.new_password1)
    resetPasswordForm.append('new_password2', form.new_password2)
    const headers = {
        'X-Requested-With': 'XMLHttpRequest',
    }
    return await http.post(url, resetPasswordForm, headers)
}

export default {
    changePassword,
    companyContext,
    createUser,
    deleteInvite,
    editInvite,
    facilityContext,
    fetchInvite,
    fetchUserIfAuthenticated,
    fetchUserProfileById,
    fetchUsersInCompanyDropdownFormat,
    fetchUsersMoenMarin,
    forgotPassword,
    login,
    logout,
    noContext,
    resetPassword,
    updateUser,
    updateUserPartial,
    validateInviteKey,
}
