<template>
    <div class="mobile-header-container" :class="{ 'mobile-header-container-solo': crumbs.length === 1 }">
        <div class="mobile-header-container-inner">
            <div v-if="crumbs.length > 1" class="mobile-header-crumb">
                <p @click="crumbClick(crumbs[crumbs.length - 2])">
                    {{ crumbs[crumbs.length - 2].title }}
                </p>
            </div>
            <div class="mobile-header-main-title" @click="crumbClick(crumbs[crumbs.length - 1])">
                <h4>{{ crumbs[crumbs.length - 1].title }}</h4>
            </div>
        </div>
        <div v-if="mobileIconButton.length > 0" class="mobile-header-button" @click.prevent="emit('headerButtonClick')">
            <MCIcon :icon="`${mobileIconButton}_standard-white`" size="28" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import { useGlobalStore } from '@/stores/global'

import type { DynamicName, MCRouteCrumb, RouteCrumb } from '@/modules/CORE/interfaces/CoreTypes'

// Props
const props = defineProps({
    mobileIconButton: {
        type: String,
        default: '',
        required: false,
    },
})

// Emits
const emit = defineEmits(['headerButtonClick'])

// Standard
const router = useRouter()
const route = router.currentRoute.value
const store = useGlobalStore()

const crumbs = computed(() => {
    const breadcrumbs = route.meta.breadcrumbs as RouteCrumb[] | undefined
    const crumbs: MCRouteCrumb[] = []
    if (breadcrumbs && breadcrumbs.length > 0) {
        for (const crumb of breadcrumbs) {
            // if crumb has fixed name; use that, else if it has dynamic name; calculate that, else default to 'NA'
            const crumbName = crumb.fixedName ? crumb.fixedName : crumb.dynamicName ? getName(crumb.dynamicName) : 'NA'
            crumbs.push({
                title: crumbName,
                route: crumb.route,
            })
        }
    }
    return crumbs
})

const crumbClick = (crumb: MCRouteCrumb) => {
    if (crumb.route === '') return

    if (crumb.route === 'back') {
        router.back()
        return
    }

    switch (crumb.route.name) {
        case 'company-facilities':
        case 'company-admin':
            router.push({ name: crumb.route.name, params: { companyId: store.currentCompany?.id } })
            break
        default:
            router.push(crumb.route)
            break
    }
}
const getName = (dynamicName: DynamicName) => {
    switch (dynamicName) {
        case 'CURRENT_COMPANY':
            return store.currentCompany ? store.currentCompany.name : 'NA'
        case 'CURRENT_FACILITY':
            return store.currentFacility ? store.currentFacility.name : 'NA'
        default:
            return 'NA'
    }
}

// on Mounted
onMounted(() => {})
</script>

<style lang="scss">
@import '@/scss/colors.scss';

.mobile-header-container {
    width: 100dvw;
    width: calc(100dvw - 2px);
    background: var(--mLINK_current-mlink-original, #112045);
    padding: 1rem 0rem;
    display: flex;
    justify-content: space-between;
    .mobile-header-container-inner {
        display: flex;
        max-height: 6.3125rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.75rem;
    }
    .mobile-header-button {
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            padding: 0.25rem;
            border-radius: 50%;
        }
    }
    .mobile-header-main-title {
        padding-left: 1rem;
        h4 {
            color: #fff;
            text-align: left;
        }
    }
    &.mobile-header-container-solo {
        height: 4.25rem;
    }
    .mobile-header-crumb {
        display: flex;
        align-items: center;
        align-self: stretch;
        padding-left: 1rem;
        .mobile-header-back-arrow {
            display: flex;
            padding: 0rem 0.5rem;
            justify-content: center;
            align-items: center;
        }
        p {
            color: var(--mLINK_current-white, #fff);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 1.3125rem */
            text-decoration-line: underline;
        }
    }
}
</style>
