import generalQueries from '@/modules/SUPPORT/graphql/generalQueries'

import { api } from './ApiSettings'
import http from './http'

import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { IClaimCreateCommentData, IClaimsHistoryPerYard, IFacilityAndBoard } from '@/modules/SUPPORT/interfaces/ISupport'
import type { AxiosResponse } from 'axios'

export default {
    async uploadAsFile(filename: string, file: File, facilityId: UUIDv4) {
        const uploadApi = api('v1/uploads/publicfile')
        const formData = new FormData()
        let responseData = null
        formData.append('facility', facilityId)
        formData.append('filename', filename)

        const URL = uploadApi

        try {
            const response = await http.post(URL, formData, {})
            responseData = await response
        } catch (e) {
            console.error(e)
        }

        formData.append('file', file)
        if (responseData && responseData?.data?.url) {
            try {
                await http.put(
                    responseData?.data.url,
                    file,
                    {
                        'Content-Type': file.type,
                    },
                    'omit',
                )
                return responseData?.data || null
            } catch (e) {
                console.error(e)
            }
        } else {
            console.error('Failed to upload file to server.')
        }
    },

    async fetchMondayBoardsForUser() {
        let boardIds: IFacilityAndBoard[] = []
        const url = api('v4/monday-boards-for-user')
        let response: Response | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                boardIds = await response.json()
            }
        } catch (err) {
            console.error('Failed to fetch monday-boards-for-user', err)
        }
        return boardIds
    },

    async createComment(data: IClaimCreateCommentData) {
        let author = '<p class="author">'
        if (data.user) {
            const fullName = `${data.user.first_name} ${data.user.last_name}`
            const email = data.user.email
            if (fullName && fullName.length > 1) {
                author += fullName
            } else if (email && email.length > 0) {
                author += email
            }
        } else {
            author += 'not found'
        }
        author += '</p>'

        const description = `<p class="description">${data.description || ''}</p>`
        const claimComment = `<p class="claim-comment">${data.claimComment || ''}</p>`

        let text = '[public]'
        text += '<br/>'
        text += author
        text += description
        text += claimComment

        if (data.attachments) {
            // Loop over object keys (multiple attachments)
            Object.keys(data.attachments).forEach((attIdx: any) => {
                const attachment = data.attachments[attIdx]
                const NAME = attachment.filename || 'NA'
                // const URL = attachment.file || attachment.image
                // new URL
                const URL = `${api('v1/uploads/publicfile')}/${attachment.id}?redirect=true`
                const txtAttachment = `<p class="attachment"><a target="blank" href="${URL}" title="${NAME}">${NAME}</a></p>`
                text += txtAttachment
            })
        }
        return generalQueries.createUpdate(data.itemId, text)
    },

    async fetchClaimsHistoryChartData(): Promise<any[]> {
        const url = api('v4/claims-history-chart-view')
        let response: Response | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                return (await response.json()) as any[]
            }
        } catch (err) {
            console.error('Failed to fetch claims-history-chart-data', err)
        }

        return []
    },

    async fetchClaimsPerYardChartData(): Promise<IClaimsHistoryPerYard[]> {
        const url = api('v4/claims-per-facility-per-yard')
        let response: Response | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                return (await response.json()) as any[]
            }
        } catch (err) {
            console.error('Failed to fetch claims-per-yard-chart-data', err)
        }

        return []
    },

    async fetchClaimsPerDesignChartData(): Promise<any[]> {
        const url = api('v4/claims-per-facility-per-design')
        let response: Response | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                return (await response.json()) as any[]
            }
        } catch (err) {
            console.error('Failed to fetch claims-per-design-chart-data', err)
        }

        return []
    },

    async fetchClaimsHistoryDiffBetweenDates(payload: any): Promise<any> {
        const url = api('v4/claims-history-diff-between-dates')
        let response: AxiosResponse | undefined
        try {
            response = await http.post(url, payload)
            if (response.status === 200) {
                return response.data
            }
        } catch (err) {
            console.error('Failed to fetch claims-history-diff-between-dates', err)
        }

        return []
    },
}
export const mondayFileFetchUrl: string = 'https://europe-west3-mlink-prod.cloudfunctions.net/monday-file?filepath='
