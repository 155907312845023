<template>
    <div class="mobile-menu-overlay-menu-items">
        <MCTextInput
            v-model="companySearch"
            :placeholder="t('common.search')"
            autofocus
            iconPlacement="left"
            icon="search"
            size="medium"
            @keyup.escape="showCompanyPicker = false"
        />
        <div
            v-for="company in searchedCompanyList"
            :key="company.id"
            style="margin-top: 1rem"
            class="mobile-menu-overlay-menu-item"
            role="button"
            tabindex="0"
            @click="goToCompany(company)"
            @keyup.space="goToCompany(company)"
            @keyup.enter="goToCompany(company)"
        >
            <MCIcon icon="business" />
            <p class="body-body">
                {{ company.name }}
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import Fuse from 'fuse.js'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useGlobalStore } from '@/stores/global'
import authApi from '@/api/auth'

import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'

import router from '@/router'

const emit = defineEmits(['closeMenu'])

// Standard
const { t } = useI18n()
const store = useGlobalStore()

// reactive variables
const showCompanyPicker = ref<boolean>(true)
const companySearch = ref<string>('')

const searchOptions = {
    includeScore: true,
    shouldSort: true,
    keys: ['name'],
}

const searchedCompanyList = computed(() => {
    const companyList = store.companyList

    if (companySearch.value.trim() === '') {
        return companyList
    }
    // fuzzy search setup
    const fuse = new Fuse(companyList, searchOptions)
    const searchResults = fuse.search(companySearch.value)

    return searchResults.map((result) => result.item)
})

const goToCompany = async (company: { id: string; name: string }) => {
    const companyId = company.id as UUIDv4
    await loadCompanyContext(companyId)
    router.push({ name: 'company-facilities', params: { companyId: company.id } })

    // hide company picker
    showCompanyPicker.value = false

    // reset search
    companySearch.value = ''

    // emit event to close menu
    emit('closeMenu')
}

const loadCompanyContext = async (companyId: UUIDv4) => {
    if (!companyId) {
        console.error('Could not get companyId')
        return
    }

    const context = await authApi.companyContext({ companyId })

    if (!context) {
        console.error('Could not establish company context')
        return
    }

    store.setCurrentCompanyAndFacility(context.companyId, context.facilityId)
}
</script>

<style lang="scss">
@import '@/scss/colors.scss';
</style>
