<template>
    <div id="global-search" :class="{ 'mobile-global-search': store.isMobileOrTabletScreenSize }">
        <!-- SEARCH -->
        <div id="global-search-input-wrapper">
            <MCTextInput
                id="search"
                v-model="search"
                icon="search"
                :width="store.isMobileScreenSize ? '95%' : ''"
                autofocus
                :placeholder="t('common.search')"
                @update:modelValue="searchTrigger"
                @keydown.escape="resetSearch"
                @keydown.arrow-down="focusNextResult"
                @keydown.arrow-up="focusPreviousResult"
            />
        </div>
        <div class="global-search-spinner">
            <MCSpinner
                v-if="
                    searchingForChecklists ||
                    searchingForCompanies ||
                    searchingForDocuments ||
                    searchingForEquipment ||
                    searchingForFacilities ||
                    searchingForWorkorders ||
                    searchingForRemarks ||
                    searchingForRules
                "
            />
        </div>
        <div v-if="showSearchResults" id="search-results">
            <div v-for="category of categoryOrder" :key="category">
                <!-- REMARK SEARCH RESULTS -->
                <div v-if="category === 'remark' && searchResultsRemarks.length > 0">
                    <h5 style="margin-top: 1rem">
                        {{ t('remarks.remarks') }}
                    </h5>
                    <section ref="resultsRef" class="search-result-section">
                        <SearchResultElement
                            v-for="(r, i) of searchResultsRemarks"
                            :key="i"
                            :elementInfo="r"
                            @escape="resetSearch"
                            @action="searchResultClick(r.route, 'remark', r.result)"
                            @next="focusNextResult"
                            @previous="focusPreviousResult"
                        />
                    </section>
                </div>
                <!-- COMPANY SEARCH RESULTS -->
                <div v-if="category === 'company' && store.currentUser?.is_superuser && searchResultsCompanies.length > 0">
                    <h5
                        v-if="store.companyList.length > 0"
                        class="clickable-group-label"
                        tabindex="0"
                        @click="companyLabelClick"
                        @keydown.space.prevent="companyLabelClick"
                        @keydown.enter.prevent="companyLabelClick"
                    >
                        {{ t('common.company') }}
                    </h5>
                    <section v-if="store.companyList.length > 0" ref="resultsRef" class="search-result-section">
                        <SearchResultElement
                            v-for="(r, i) of searchResultsCompanies"
                            :key="i"
                            :elementInfo="r"
                            @escape="resetSearch"
                            @action="searchResultClick(r.route, 'company', r.result)"
                            @next="focusNextResult"
                            @previous="focusPreviousResult"
                        />
                    </section>
                </div>
                <!-- FACILITY SEARCH RESULTS -->
                <div v-if="category === 'facility' && searchResultsFacilities.length > 0">
                    <h5 style="margin-top: 1rem">
                        {{ t('common.facility') }}
                    </h5>
                    <section :ref="store.currentUser?.is_superuser ? '' : 'resultsRef'" class="search-result-section">
                        <SearchResultElement
                            v-for="(r, i) of searchResultsFacilities"
                            :key="i"
                            :elementInfo="r"
                            @escape="resetSearch"
                            @action="searchResultClick(r.route, 'facility', r.result)"
                            @next="focusNextResult"
                            @previous="focusPreviousResult"
                        />
                    </section>
                </div>
                <!-- EQUIPMENT SEARCH RESULTS -->
                <div v-if="category === 'equipment' && searchResultsEquipment.length > 0">
                    <h5 style="margin-top: 1rem">
                        {{ t('side_nav.equipment') }}
                    </h5>
                    <section ref="resultsRef" class="search-result-section">
                        <SearchResultElement
                            v-for="(r, i) of searchResultsEquipment"
                            :key="i"
                            :elementInfo="r"
                            @escape="resetSearch"
                            @action="searchResultClick(r.route, 'equipment', r.result)"
                            @next="focusNextResult"
                            @previous="focusPreviousResult"
                        />
                    </section>
                </div>
                <!-- DOCUMENT SEARCH RESULTS -->
                <div v-if="category === 'document' && searchResultsDocuments.length > 0">
                    <h5 style="margin-top: 1rem">
                        {{ t('side_nav.documents') }}
                    </h5>
                    <section ref="resultsRef" class="search-result-section">
                        <SearchResultElement
                            v-for="(r, i) of searchResultsDocuments"
                            :key="i"
                            :elementInfo="r"
                            @escape="resetSearch"
                            @action="searchResultClick(r.route, 'document', r.result)"
                            @next="focusNextResult"
                            @previous="focusPreviousResult"
                        />
                    </section>
                </div>
                <!-- WORKORDER SEARCH RESULTS -->
                <div v-if="category === 'workorder' && searchResultsWorkorders.length > 0">
                    <h5 style="margin-top: 1rem">
                        {{ t('side_nav.workorders') }}
                    </h5>
                    <section ref="resultsRef" class="search-result-section">
                        <SearchResultElement
                            v-for="(r, i) of searchResultsWorkorders"
                            :key="i"
                            :elementInfo="r"
                            @escape="resetSearch"
                            @action="searchResultClick(r.route, 'workorder', r.result)"
                            @next="focusNextResult"
                            @previous="focusPreviousResult"
                        />
                    </section>
                </div>
                <!-- CHECKLIST SEARCH RESULTS -->
                <div v-if="category === 'checklist' && searchResultsChecklists.length > 0">
                    <h5 style="margin-top: 1rem">
                        {{ t('side_nav.checklists') }}
                    </h5>
                    <section ref="resultsRef" class="search-result-section">
                        <SearchResultElement
                            v-for="(r, i) of searchResultsChecklists"
                            :key="i"
                            :elementInfo="r"
                            @escape="resetSearch"
                            @action="searchResultClick(r.route, 'checklist', r.result)"
                            @next="focusNextResult"
                            @previous="focusPreviousResult"
                        />
                    </section>
                </div>

                <!-- RULE SEARCH RESULTS -->
                <div v-if="category === 'rule' && searchResultsRules.length > 0">
                    <h5 style="margin-top: 1rem">
                        {{ t('common.rules') }}
                    </h5>
                    <section ref="resultsRef" class="search-result-section">
                        <SearchResultElement
                            v-for="(r, i) of searchResultsRules"
                            :key="i"
                            :elementInfo="r"
                            @escape="resetSearch"
                            @action="searchResultClick(r.route, 'rule', r.result)"
                            @next="focusNextResult"
                            @previous="focusPreviousResult"
                        />
                    </section>
                </div>
            </div>
            <div v-if="showEmptyState">
                <LCEmptyState v-if="showEmptyState" :title="t('global-search.empty-state')" />
            </div>
            <!-- INFO BANNER -->
        </div>
        <MCInfoBanner
            v-if="store.isDesktopScreenSize && (search === '' || !search)"
            type="info"
            :label="t('global-search.info-box')"
            style="margin-top: 0.875rem"
        />
    </div>
</template>

<script setup lang="ts">
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { LCEmptyState } from '@/components/generic'
import { SearchResultElement } from '@/components/globalsearch'
import { useGlobalStore } from '@/stores/global'
import authApi from '@/api/auth'
import checklistApi from '@/api/checklists'
import documentsApi from '@/api/documents'
import equipmentApi from '@/api/equipment'
import facilityApi from '@/api/facilities'
import remarksApi from '@/api/remarks'
import workorderApi from '@/api/workorders'

import type { ISimpleCompany } from '@/modules/CORE/company/interfaces/ICompany'
import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { IFuseCompany } from '@/modules/CORE/interfaces/CoreTypes'
import type {
    ISearchResponseChecklist,
    ISearchResponseRoutine,
    ISearchResponseWorkorder,
    ISearchResult,
    ISearchResultType,
} from '@/modules/CORE/search/interfaces/ISearch'
import type { RouteLocationRaw } from 'vue-router'

import { currentLocale } from '@/i18n'

// emits
const emit = defineEmits(['searchResultClicked', 'escapeClicked'])
// store & router & translate
const router = useRouter()
const store = useGlobalStore()
const { t } = useI18n()

// reactive variables
const resultsRef = ref<HTMLElement | null>(null)
const search = ref<string>('')
const showSearchResults = ref<boolean>(false)
const searchResultsChecklists = ref<ISearchResult[]>([])
const searchResultsCompanies = ref<ISearchResult[]>([])
const searchResultsDocuments = ref<ISearchResult[]>([])
const searchResultsEquipment = ref<ISearchResult[]>([])
const searchResultsFacilities = ref<ISearchResult[]>([])
const searchResultsRemarks = ref<ISearchResult[]>([])
const searchResultsRules = ref<ISearchResult[]>([])
const searchResultsWorkorders = ref<ISearchResult[]>([])

const searchingForChecklists = ref<boolean>(false)
const searchingForCompanies = ref<boolean>(false)
const searchingForDocuments = ref<boolean>(false)
const searchingForEquipment = ref<boolean>(false)
const searchingForFacilities = ref<boolean>(false)
const searchingForRemarks = ref<boolean>(false)
const searchingForRules = ref<boolean>(false)
const searchingForWorkorders = ref<boolean>(false)

const currentResultIndex = ref<number>(0)
const lastDirection = ref<string>('down')

// computed
const companies = computed(() => {
    return store.companyList.map((company: ISimpleCompany) => ({
        id: company.id,
        value: company.name,
    }))
})

const categoryOrder = computed(() => {
    // the search match with highest score dictates the order of the categories
    const highestMatch = {
        company: Math.max(...searchResultsCompanies.value.map((result) => result.match), 0),
        facility: Math.max(...searchResultsFacilities.value.map((result) => result.match), 0),
        equipment: Math.max(...searchResultsEquipment.value.map((result) => result.match), 0),
        document: Math.max(...searchResultsDocuments.value.map((result) => result.match), 0),
        workorder: Math.max(...searchResultsWorkorders.value.map((result) => result.match), 0),
        remark: Math.max(...searchResultsRemarks.value.map((result) => result.match), 0),
        rule: Math.max(...searchResultsRules.value.map((result) => result.match), 0),
        checklist: Math.max(...searchResultsChecklists.value.map((result) => result.match), 0),
    }

    const categoriesWithMatch = [
        { category: 'company', match: highestMatch.company },
        { category: 'facility', match: highestMatch.facility },
        { category: 'equipment', match: highestMatch.equipment },
        { category: 'document', match: highestMatch.document },
        { category: 'workorder', match: highestMatch.workorder },
        { category: 'remark', match: highestMatch.remark },
        { category: 'rule', match: highestMatch.rule },
        { category: 'checklist', match: highestMatch.checklist },
    ]

    // Sort categories by highest match
    categoriesWithMatch.sort((a, b) => b.match - a.match)

    return categoriesWithMatch.map((item) => item.category)
})

// methods
const focusNextResult = () => {
    try {
        if (lastDirection.value === 'up') {
            currentResultIndex.value = currentResultIndex.value + 2
        }
        const pElement: HTMLElement = resultsRef.value?.children[currentResultIndex.value] as HTMLElement
        const aElement: any = pElement.children[0]
        aElement.focus()
        currentResultIndex.value++
        lastDirection.value = 'down'
    } catch (err) {
        console.error(err)
        currentResultIndex.value = 5
    }
}

const focusPreviousResult = () => {
    try {
        if (lastDirection.value === 'down') {
            currentResultIndex.value = currentResultIndex.value - 2
        }
        const pElement: HTMLElement = resultsRef.value?.children[currentResultIndex.value] as HTMLElement
        const aElement: any = pElement.children[0]
        aElement.focus()
        currentResultIndex.value--
        lastDirection.value = 'up'
    } catch (err) {
        console.error(err)
        currentResultIndex.value = -1
    }
}

const companyLabelClick = () => {
    router.push({ name: 'superuser-company-management' })
}

const searchResultClick = async (route: RouteLocationRaw, context: ISearchResultType, result: any) => {
    resetSearch()

    // close global search parent component
    emit('searchResultClicked')

    let triggerGlobalSearchPulse = true

    if (context === 'facility') {
        const facilityResult: any = result
        const facilityId: UUIDv4 = facilityResult.id as UUIDv4
        const companyId: UUIDv4 = facilityResult.company_id as UUIDv4

        // change company and facility in state and then push route
        await store.setCurrentCompanyAndFacility(companyId, facilityId)

        // ! JK: triggering global search pulse led to a bug (see ML-737) so I worked around it
        triggerGlobalSearchPulse = false

        setTimeout(() => {
            router.push(route)
        }, 333)
    } else if (context === 'company') {
        const companyResult: IFuseCompany = result
        const companyId: UUIDv4 = companyResult.item.id as UUIDv4
        loadCompanyContext(companyId)
        setTimeout(() => {
            router.push(route)
        }, 333)
    } else if (context === 'remark') {
        const remarkResult: any = route
        const facId = remarkResult.params.facilityId
        store.setCurrentFacilityByFacilityId(facId)
        setTimeout(() => {
            router.push(route)
        }, 333)
    } else {
        router.push(route)
    }

    if (store.isMobileScreenSize) {
        emit('searchResultClicked')
    }

    if (triggerGlobalSearchPulse) {
        setTimeout(() => {
            // Trigger update of components showing results for global search
            store.triggerGlobalSearchPulse()
        }, 100)
    }
}

const resetSearch = () => {
    showSearchResults.value = false
    search.value = ''
    currentResultIndex.value = 0
    lastDirection.value = 'down'
}

const searchFunction = () => {
    // This is debounced to avoid spamming the server with requests
    // only search for company for users with many companies
    if (store.companyList.length > 0) {
        companySearchFunction()
    }

    facilitySearchFunction().then(async () => {
        searchRecentFaciliesByCompany()
    })
    equipmentSearchFunction()
    documentSearchFunction()
    workorderSearchFunction()
    checklistSearchFunction()
    ruleSearchFunction()
    remarkSearchFunction()
    // DISPLAY SEARCH RESULTS
    if (search.value === '') {
        showSearchResults.value = false
    } else {
        showSearchResults.value = true
    }
}

const companySearchFunction = () => {
    if (!store.currentUser?.is_superuser) return
    searchingForCompanies.value = true
    const companiesSearchOptions = {
        includeScore: true,
        keys: ['value'],
    }
    const companiesTop5Results: IFuseCompany[] = top5fuzzyAndSort(companies.value, companiesSearchOptions)
    const results: ISearchResult[] = []

    for (const result of companiesTop5Results) {
        results.push({
            label: result.item.value,
            route: { name: 'company-facilities', params: { companyId: result.item.id } },
            type: 'company',
            secondLabel: '',
            match: matchPercentage(result.score),
            result,
        })
    }
    searchResultsCompanies.value = results
    searchingForCompanies.value = false
}

const facilitySearchFunction = async (): Promise<void> => {
    searchingForFacilities.value = true
    const facilitiesToCheck = store.currentUser?.view_facility_permissions ?? []
    const facilitiesTop5Results = await facilityApi.globalFacilitySearch(facilitiesToCheck, search.value)
    const facilitiesResults: ISearchResult[] = []
    for (const result of facilitiesTop5Results) {
        const footerLabels: string[] = []
        if (store.currentUser?.is_superuser && result.build_no_external) {
            footerLabels.push(`${result.build_no_external}`)
        }
        if (result.build_no_internal) footerLabels.push(`${result.build_no_internal}`)

        if (result.type && result.type.length > 0) {
            footerLabels.push(t(`facilityTypes.${result.type}`))
        }
        facilitiesResults.push({
            label: result.name,
            route: { name: 'facility-detail', params: { facilityId: result.id } },
            type: 'facility',
            match: 99,
            secondLabel: result.company_name ?? '',
            footerLabels,
            result,
        })
    }
    searchResultsFacilities.value = facilitiesResults
    searchingForFacilities.value = false
}

const equipmentSearchFunction = async () => {
    if (!store.currentCompany) return
    if (!store.currentUser) return
    searchingForEquipment.value = true
    searchResultsEquipment.value = []

    const equipmentSearchCurrentFacilityAPI = await equipmentApi.searchEquipmentByFacilityIds(
        store.currentCompany.id,
        [store.currentFacility?.id],
        search.value,
        currentLocale.value,
        true,
    )

    const results: ISearchResult[] = []
    if (equipmentSearchCurrentFacilityAPI) {
        for (const result of equipmentSearchCurrentFacilityAPI) {
            const footerlabels: string[] = []
            if (result.classification) {
                footerlabels.push(`${result.classification} ${result.classification_verbose}`)
            }
            if (result.location) {
                footerlabels.push(result.location)
            }
            results.push({
                match: result.total_similarity,
                label: `${result.description} - ${result.facility}`,
                route: {
                    name: 'equipment-list',
                    query: { equipmentId: result.model ?? result.description },
                    params: { facilityId: store.currentFacility?.id },
                },
                secondLabel: result.model ? `${result.model}` : '',
                type: 'equipment',
                footerLabels: footerlabels,
            })
        }
    }
    searchResultsEquipment.value = results

    if (
        (store.currentUser?.view_facility_permissions && store.currentUser?.view_facility_permissions.length > 0) ||
        store.currentUser?.is_superuser
    ) {
        const otherFacilities = store.currentUser.view_facility_permissions.filter((facility) => facility !== store.currentFacility?.id)

        const equipmentSearchRestFacilitiesAPI = await equipmentApi.searchEquipmentByFacilityIds(
            store.currentCompany.id,
            otherFacilities,
            search.value,
            currentLocale.value,
            false,
        )
        if (equipmentSearchRestFacilitiesAPI) {
            for (const result of equipmentSearchRestFacilitiesAPI) {
                const footerlabels: string[] = []
                if (result.classification) {
                    footerlabels.push(`${result.classification} ${result.classification_verbose}`)
                }
                if (result.location) {
                    footerlabels.push(result.location)
                }
                searchResultsEquipment.value.push({
                    match: result.total_similarity,
                    label: `${result.description} - ${result.facility}`,
                    route: {
                        name: 'equipment-list',
                        query: { equipmentId: result.model ?? result.description },
                        params: { facilityId: result.facility_id },
                    },
                    secondLabel: result.model ? `${result.model}` : '',
                    type: 'equipment',
                    footerLabels: footerlabels,
                })
            }
        }
    }

    searchingForEquipment.value = false
}

const remarkSearchFunction = async () => {
    if (!store.currentCompany) return
    if (!store.currentUser) return
    if (!store.currentFacility) return
    searchingForRemarks.value = true
    searchResultsRemarks.value = []
    const whatRemarksToSearch = store.currentUser.is_superuser ? 'all' : store.currentUser.view_facility_permissions
    const remarkSearch = await remarksApi.searchRemarksByFacilityIds(store.currentFacility.id, whatRemarksToSearch, search.value)
    const results: ISearchResult[] = []
    if (remarkSearch) {
        for (const result of remarkSearch) {
            const footerlabels: string[] = []
            if (result.internal_status) {
                footerlabels.push(t(`remarks.statuses.${result.internal_status}`))
            }
            results.push({
                match: result.total_similarity,
                label: `${result.title}`,
                route: {
                    name: 'remarks-page',
                    query: { remarkId: result.id },
                    params: { facilityId: result.facility_id },
                },
                secondLabel: result.facility_name ? `${result.facility_name}` : '',
                type: 'remark',
                footerLabels: footerlabels,
            })
        }
    }
    searchResultsRemarks.value = results
    searchingForRemarks.value = false
}

const documentSearchFunction = async () => {
    if (!store.currentCompany) return
    if (!store.currentUser) return
    searchingForDocuments.value = true
    searchResultsDocuments.value = []
    const documentsSearchCurrentFacilityAPI = await documentsApi.searchDocumentByFacilityIds(
        store.currentCompany.id,
        [store.currentFacility?.id],
        search.value,
        true,
    )

    const results: ISearchResult[] = []
    if (documentsSearchCurrentFacilityAPI) {
        for (const result of documentsSearchCurrentFacilityAPI) {
            const descriptionOrName = result.description ?? result.name
            results.push({
                label: descriptionOrName,
                route: { name: 'document-list', query: { documentId: result.id }, params: { facilityId: store.currentFacility?.id } },
                type: 'document',
                match: result.total_similarity,
                secondLabel: result.type ? `${t(`document.types.${result.type}`)}. ${result.facility}` : result.facility,
                result,
            })
        }
    }
    searchResultsDocuments.value = results
    if (
        (store.currentUser?.view_facility_permissions && store.currentUser?.view_facility_permissions.length > 0) ||
        store.currentUser?.is_superuser
    ) {
        const otherFacilities = store.currentUser.view_facility_permissions.filter((facility) => facility !== store.currentFacility?.id)
        const documentSearchRestFacilitiesAPI = await documentsApi.searchDocumentByFacilityIds(
            store.currentCompany.id,
            otherFacilities,
            search.value,
            false,
        )
        if (documentSearchRestFacilitiesAPI) {
            for (const result of documentSearchRestFacilitiesAPI) {
                const descriptionOrName = result.description ?? result.name
                searchResultsDocuments.value.push({
                    label: descriptionOrName,
                    route: { name: 'document-list', query: { documentId: result.id }, params: { facilityId: result.facility_id } },
                    type: 'document',
                    match: result.total_similarity,
                    secondLabel: result.type ? `${t(`document.types.${result.type}`)}. ${result.facility}` : result.facility,
                    result,
                })
            }
        }
    }

    searchingForDocuments.value = false
}

const workorderSearchFunction = async () => {
    searchingForWorkorders.value = true
    const workorderSearchAPI = await workorderApi.searchWorkorderByFacilityIds([store.currentFacility?.id], search.value)

    const results: ISearchResult[] = []
    for (const result of workorderSearchAPI as ISearchResponseWorkorder[]) {
        const descriptionOrName = result.title
        results.push({
            label: descriptionOrName,
            route: { name: 'workorder-page', params: { facilityId: store.currentFacility?.id }, query: { workorderId: result.id } },
            type: 'workorder',
            match: result.total_similarity,
            secondLabel: t(`workorder.type.${result.type}_short`),
            footerLabels: [result.description ?? ''],
            result,
        })
    }

    searchResultsWorkorders.value = results
    searchingForWorkorders.value = false
}

const checklistSearchFunction = async () => {
    if (!store.currentUser) return
    if (!store.currentFacility) return
    searchingForChecklists.value = true
    const currentFacilityId = store.currentFacility?.id as UUIDv4
    const checklistSearchCurrentFacilityAPI = await checklistApi.searchChecklistByFacilityIds([currentFacilityId], search.value)

    const results: ISearchResult[] = []
    for (const result of checklistSearchCurrentFacilityAPI as ISearchResponseChecklist[]) {
        const descriptionOrName = result.name
        results.push({
            label: descriptionOrName,
            route: { name: 'checklist-page', params: { facilityId: currentFacilityId }, query: { checklistId: result.id } },
            type: 'checklist',
            match: result.name_similarity,
            secondLabel:
                result.connected_rules > 0
                    ? `${result.connected_rules} ${result.connected_rules === 1 ? t('checklist.connected_routines_singular') : t('checklist.connected_routines_plural')}`
                    : '',
            footerLabels: store.currentUser.view_facility_permissions.length !== 1 ? [result.facility] : [],
            result,
        })
    }
    searchResultsChecklists.value = results
    const otherFacilities = store.currentUser.view_facility_permissions.filter((facility) => facility !== store.currentFacility?.id)

    if (otherFacilities.length > 0) {
        const checklistSearchOtherFacilitiesAPI = await checklistApi.searchChecklistByFacilityIds(otherFacilities, search.value)
        for (const result of checklistSearchOtherFacilitiesAPI as ISearchResponseChecklist[]) {
            const descriptionOrName = result.name
            searchResultsChecklists.value.push({
                label: descriptionOrName,
                route: { name: 'checklist-page', params: { facilityId: result.facility_id }, query: { checklistId: result.id } },
                type: 'checklist',
                match: result.name_similarity,
                secondLabel:
                    result.connected_rules > 0
                        ? `${result.connected_rules} ${result.connected_rules === 1 ? t('checklist.connected_routines_singular') : t('checklist.connected_routines_plural')}`
                        : '',
                footerLabels: [result.facility],
                result,
            })
        }
    }
    searchResultsChecklists.value = results
    searchingForChecklists.value = false
}

const ruleSearchFunction = async () => {
    if (!store.currentFacility) return
    if (!store.currentUser) return
    searchingForRules.value = true
    searchResultsRules.value = []

    const ruleSearchCurrentFacilityAPI = await checklistApi.searchRulesByFacilityIds([store.currentFacility?.id], search.value)
    const results: ISearchResult[] = []
    for (const result of ruleSearchCurrentFacilityAPI as ISearchResponseRoutine[]) {
        results.push({
            label: result.name,
            route: {
                name: 'checklist-page',
                params: { facilityId: store.currentFacility?.id },
                query: { checklistId: result.checklist_id, ruleId: result.id },
            },
            type: 'rule',
            match: result.name_similarity,
            secondLabel: t(`checklist.${result.rule_type}`),
            footerLabels: [result.facility],
            result,
        })
    }

    searchResultsRules.value = results
    const otherFacilities = store.currentUser.view_facility_permissions.filter((facility) => facility !== store.currentFacility?.id)

    const ruleSearchOtherFacilitiesAPI = await checklistApi.searchRulesByFacilityIds(otherFacilities, search.value)
    for (const result of ruleSearchOtherFacilitiesAPI as ISearchResponseRoutine[]) {
        searchResultsRules.value.push({
            label: result.name,
            route: {
                name: 'checklist-page',
                params: { facilityId: result.facility_id },
                query: { checklistId: result.checklist_id, ruleId: result.id },
            },
            type: 'rule',
            match: result.name_similarity,
            secondLabel: t(`checklist.${result.rule_type}`),
            footerLabels: [result.facility],
            result,
        })
    }
    searchingForRules.value = false
}

const searchRecentFaciliesByCompany = async (): Promise<void> => {
    if (searchResultsCompanies.value.length === 0) return
    if (search.value.toLocaleUpperCase() !== searchResultsCompanies.value[0].label.toLocaleUpperCase()) return
    const excludedFacilities = searchResultsFacilities.value.map((facility) => facility.result.id)
    const newFacilities = await facilityApi.globalFacilitySearchRecent(
        searchResultsCompanies.value[0].route.params.companyId as UUIDv4,
        excludedFacilities,
    )
    for (const result of newFacilities) {
        const footerLabels: string[] = []
        if (store.currentUser?.is_superuser && result.build_no_external) {
            footerLabels.push(`${result.build_no_external}`)
        }
        if (result.build_no_internal) footerLabels.push(`${result.build_no_internal}`)

        if (result.type && result.type.length > 0) {
            footerLabels.push(t(`facilityTypes.${result.type}`))
        }
        searchResultsFacilities.value.push({
            label: result.name,
            route: { name: 'facility-detail', params: { facilityId: result.id } },
            type: 'facility',
            match: 99,
            secondLabel: result.company_name ?? '',
            footerLabels,
            result,
        })
    }
    searchingForFacilities.value = false
}

const top5fuzzyAndSort = (searchList: any[], searchOptions: { includeScore: boolean; keys: string[] }): any[] => {
    // fuzzy search setup
    const fuse = new Fuse(searchList, searchOptions)
    const searchResults = fuse.search(search.value)

    // make sure that the best match is first
    const sortedResults = searchResults.sort((a: any, b: any) => a.score - b.score)

    // return the top 5 results
    return sortedResults.slice(0, 5) as any[]
}

const searchTrigger = debounce(searchFunction, 400)

const matchPercentage = (score: number): number => {
    return Math.round((1 - score) * 100)
}

const populateCompanyList = async () => {
    store.companyList = await store.fetchCompaniesByUser()
}

const loadCompanyContext = async (companyId: UUIDv4) => {
    if (!companyId) {
        console.error('Could not get companyId')
        return
    }

    const context = await authApi.companyContext({ companyId })

    if (!context) {
        console.error('Could not establish company context')
        return
    }

    store.setCurrentCompanyAndFacility(context.companyId, context.facilityId)
}

// Emptystate
const showEmptyState = computed(() => {
    return (
        search.value.length > 0 &&
        searchResultsChecklists.value.length === 0 &&
        searchResultsCompanies.value.length === 0 &&
        searchResultsDocuments.value.length === 0 &&
        searchResultsEquipment.value.length === 0 &&
        searchResultsFacilities.value.length === 0 &&
        searchResultsRules.value.length === 0 &&
        searchResultsRemarks.value.length === 0 &&
        searchResultsWorkorders.value.length === 0 &&
        showSearchResults.value &&
        searchResultsChecklists.value.length === 0 &&
        searchingForCompanies.value === false &&
        searchingForDocuments.value === false &&
        searchingForEquipment.value === false &&
        searchingForFacilities.value === false &&
        searchingForRules.value === false &&
        searchingForRemarks.value === false &&
        searchingForWorkorders.value === false
    )
})
// lifecycle hooks
onMounted(async () => {
    await populateCompanyList()
    store.companyList = await store.fetchCompaniesByUser()
})

// Escape close
const closeSearch = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
        emit('escapeClicked')
    }
}

onMounted(() => {
    document.addEventListener('keydown', closeSearch)
})

onUnmounted(() => {
    document.removeEventListener('keydown', closeSearch)
})
</script>

<style lang="scss">
@import '@/scss/colors.scss';
@import '@/scss/breakpoints.scss';
@import '@/scss/scrollbar.scss';

#global-search {
    position: absolute;
    top: 12.5%;
    width: 39.3125rem;
    height: fit-content;
    max-height: 46.5625rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0.625rem;
    background: var(--mLINK_current-background, #f3f3f3);

    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 1.625rem;
    padding-bottom: 1.625rem;

    &.mobile-global-search {
        top: 4rem;
        width: 100dvw;
        height: 100dvh;
        padding: 0rem;
        position: fixed;
        border-radius: unset;
        #global-search-input-wrapper {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
    #global-search-input-wrapper {
        margin-bottom: 1.375rem;
        border-radius: 1.25rem;
        width: 100%;
    }
}

#search {
    // overriding MCTextInput to get searchfield to be like Figma
    height: 2.875rem;

    .mc-input-parent {
        &:focus-within {
            box-shadow: none !important;
        }
        border-radius: 1.25rem !important;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 0.075rem solid #d9d9d9;

        border-radius: 1.25rem;
        border: 0.0625rem solid var(--mLINK-neutrals-gray5, #77838c);
        background: #fff;

        width: 100%;

        .mc-input-icon-left {
            .mc-input {
                font-size: 0.875rem;
            }
        }
        @include breakpoint-mobile {
            margin-left: 0.5rem;
        }
    }
}

#search-results {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: fit-content;
    max-height: calc(100dvh - 10rem);
    min-height: 10rem;
    width: fit-content;
    padding: 1rem;
    border-radius: 0.625rem;
    z-index: 1;
    width: 100%;
    @include mac-scrollbar-vertical;

    h5 {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        box-shadow: 0.125rem 0.25rem 0.5rem 0rem rgba(0, 0, 0, 0.1);
        background-color: $standard-white;
        padding: 1rem;
        border-bottom: 2px solid $gray-lightest;
        position: sticky;
        top: -1rem;
        color: $interactive-darkest;
    }
    p > span {
        color: #ccc;
    }

    @include breakpoint-mobile {
        border-radius: unset;
        top: 5.5rem;
        box-shadow: unset;
        left: 0rem;
        width: calc(100% - 2rem);
        max-height: calc(100dvh - 11.75rem);
        height: calc(100dvh - 11.75rem);
    }
}

.clickable-group-label {
    cursor: pointer;
    &:hover {
        color: #555;
        background-color: #f0f0f0;
    }
}

section.search-result-section {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    align-self: stretch;
    border-bottom: 0.0625rem solid $gray-lightest;
    cursor: pointer;
    :hover {
        background-color: $interactive-lightest;
    }
}
</style>
