<template>
    <!-- desktop breadcrumbs -->
    <MCBreadCrumbs v-if="showDesktopCrumbs" :crumbs="crumbs" @crumbClick="crumbClick" />

    <!-- mobile breadcrumbs -->
    <MCBreadCrumbs v-if="showMobileCrumbs" :crumbs="[mobileCrumb]" @crumbClick="crumbClick" />

    <!-- message to devs if they forget to define breadcrumbs in the router -->
    <p v-if="crumbs.length === 0">
        <b style="color: red">NB! </b>Breadcrumbs not defined for this route - please configure it in the router/index.ts file
    </p>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useGlobalStore } from '@/stores/global'

import type { DynamicName, MCRouteCrumb, RouteCrumb } from '@/modules/CORE/interfaces/CoreTypes'

const router = useRouter()
const route = router.currentRoute.value
const store = useGlobalStore()
const { t } = useI18n()

const showDesktopCrumbs = computed(() => {
    // need to have some crumbs and not be on mobile to show desktop crumbs
    if (crumbs.value.length === 0) return false
    return !store.isMobileScreenSize
})

const showMobileCrumbs = computed(() => {
    // need to have some crumbs and be on mobile to show mobile crumbs
    if (crumbs.value.length === 0) return false
    return store.isMobileScreenSize
})

const mobileCrumb = computed(() => {
    // on mobile we are just showing one crumb + a back arrow/chevron
    // this logic is to determine which crumb to show on mobile
    // i.e. return the second last crumb if at least two crumbs, else return a default back crumb
    const atLeastTwoCrumbs = crumbs.value.length >= 2
    if (atLeastTwoCrumbs) {
        return crumbs.value[crumbs.value.length - 2]
    } else {
        return { title: t('common.back'), route: { name: 'back' } }
    }
})

const crumbClick = (crumb: MCRouteCrumb) => {
    if (crumb.route === '') return

    if (crumb.route === 'back') {
        router.back()
        return
    }

    switch (crumb.route.name) {
        case 'company-facilities':
        case 'company-admin':
            router.push({ name: crumb.route.name, params: { companyId: store.currentCompany?.id } })
            break
        default:
            router.push(crumb.route)
            break
    }
}

const getName = (dynamicName: DynamicName) => {
    switch (dynamicName) {
        case 'CURRENT_COMPANY':
            return store.currentCompany ? store.currentCompany.name : 'NA'
        case 'CURRENT_FACILITY':
            return store.currentFacility ? store.currentFacility.name : 'NA'
        default:
            return 'NA'
    }
}

const crumbs = computed(() => {
    const breadcrumbs = route.meta.breadcrumbs as RouteCrumb[] | undefined
    const crumbs: MCRouteCrumb[] = []
    if (breadcrumbs && breadcrumbs.length > 0) {
        for (const crumb of breadcrumbs) {
            // if crumb has fixed name; use that, else if it has dynamic name; calculate that, else default to 'NA'
            const crumbName = crumb.fixedName ? crumb.fixedName : crumb.dynamicName ? getName(crumb.dynamicName) : 'NA'
            crumbs.push({
                title: crumbName,
                route: crumb.route,
            })
        }
    }
    return crumbs
})
</script>
