import type { DirectiveBinding } from 'vue'

// this directive is used to detect click outside of the element
// example usage:
// <div v-click-outside="closeModal">...</div>
// it will also not allow the user to click outside
// accidentally with only a mouseup-event, both
// mousedown and mouseup must occur outside the element

// eager usage (will call the method on mousedown and not wait for mouseup):
// <div v-click-outside.eager="closeModal">...</div>

const ClickOutsideDirective = {
    beforeMount(el: HTMLElement, binding: DirectiveBinding<() => void>) {
        let isMouseDownInside: undefined | boolean
        let isMouseUpInside: undefined | boolean

        const handleMouseDown = () => {
            isMouseDownInside = true
        }

        const handleMouseUp = () => {
            isMouseUpInside = true
        }

        const handleClickOutside = () => {
            if (binding.modifiers?.eager) {
                if (!(isMouseDownInside === true && isMouseUpInside === true)) {
                    binding.value()
                }
            }
            if (isMouseDownInside === false && isMouseUpInside === false) {
                // If both mousedown and mouseup events occurred outside the bound area, call the provided method
                binding.value()
            }
            // Reset flags for subsequent clicks
            isMouseDownInside = false
            isMouseUpInside = false
        }

        // Add event listeners to the element to track mousedown and mouseup events
        el.addEventListener('mousedown', handleMouseDown)
        el.addEventListener('mouseup', handleMouseUp)

        // Add event listener to the document to handle clicks outside the bound area
        document.addEventListener('click', handleClickOutside)

        // Store the event listeners so we can remove them later
        // @ts-ignore-next-line
        el._clickOutsideHandlers = {
            handleMouseDown,
            handleMouseUp,
            handleClickOutside,
        }
    },

    unmounted(el: HTMLElement) {
        // Remove the event listeners when the element is unmounted
        // @ts-ignore-next-line
        const { handleMouseDown, handleMouseUp, handleClickOutside } = el._clickOutsideHandlers
        el.removeEventListener('mousedown', handleMouseDown)
        el.removeEventListener('mouseup', handleMouseUp)
        document.removeEventListener('click', handleClickOutside)
    },
}

export default ClickOutsideDirective
