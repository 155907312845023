import { attachmentCount, mapColumnValuesByFieldname, mapPublicCommentsFromClaim } from '../utils'

import type { IClaimListElement, IClaimPendingListElement, IClaimPriority, IClaimStatus } from '../../interfaces/ISupport'

// This file contains the mapping of different needed versions of the Claims type
export default {
    mapPendingClaims: (claimItems: any, type: 'claims' | 'support') => {
        const claimsList = [] as IClaimPendingListElement[]
        for (let i = 0; i < claimItems.length; i++) {
            for (let y = 0; y < claimItems[i].items_page.items.length; y++) {
                const claimItem = {} as IClaimPendingListElement

                // claimItem['attachments'] = attachmentCount(claimItems[i].items_page.items[y])
                claimItem.attachments_count = attachmentCount(claimItems[i].items_page.items[y])
                claimItem.criticality = mapColumnValuesByFieldname('Criticality', claimItems[i].items_page.items[y]) as string
                claimItem.comment_count_public = mapPublicCommentsFromClaim(claimItems[i].items_page.items[y].updates)
                claimItem.comments = claimItems[i].items_page.items[y].updates
                claimItem.date = mapColumnValuesByFieldname('Date filed', claimItems[i].items_page.items[y]) as string
                claimItem.equipment = mapColumnValuesByFieldname('Equipment', claimItems[i].items_page.items[y]) as string
                claimItem.mlink_id = mapColumnValuesByFieldname('mLink Claim ID', claimItems[i].items_page.items[y]) as string
                claimItem.monday_id = claimItems[i].items_page.items[y].id
                claimItem.priority = mapColumnValuesByFieldname('Customer Priority', claimItems[i].items_page.items[y]) as IClaimPriority
                claimItem.sfiCodes = mapColumnValuesByFieldname('SFI Codes', claimItems[i].items_page.items[y]) as string
                claimItem.status = mapColumnValuesByFieldname('Status', claimItems[i].items_page.items[y]) as IClaimStatus
                claimItem.title = claimItems[i].items_page.items[y].name
                claimItem.type = type
                claimItem.updated_at = claimItems[i].items_page.items[y].updated_at
                if (Object.keys(claimItem).length !== 0) claimsList.push(claimItem)
            }
        }
        return claimsList
    },
    mapNotPendingClaims: (claimItems: any, type: 'claims' | 'support') => {
        const claimsList = [] as IClaimListElement[]
        for (let i = 0; i < claimItems.length; i++) {
            for (let y = 0; y < claimItems[i].items_page.items.length; y++) {
                const claimItem = {} as IClaimListElement
                claimItem.attachments = []
                claimItem.criticality = mapColumnValuesByFieldname('Criticality', claimItems[i].items_page.items[y]) as string
                claimItem.date = mapColumnValuesByFieldname('Date filed', claimItems[i].items_page.items[y]) as string
                claimItem.equipment = mapColumnValuesByFieldname('Equipment', claimItems[i].items_page.items[y]) as string
                claimItem.mlink_id = mapColumnValuesByFieldname('mLink Claim ID', claimItems[i].items_page.items[y]) as string
                claimItem.monday_id = claimItems[i].items_page.items[y].id
                claimItem.priority = mapColumnValuesByFieldname('Customer Priority', claimItems[i].items_page.items[y]) as IClaimPriority
                claimItem.sfiCodes = mapColumnValuesByFieldname('SFI Codes', claimItems[i].items_page.items[y]) as string
                claimItem.status = mapColumnValuesByFieldname('Status', claimItems[i].items_page.items[y]) as IClaimStatus
                claimItem.title = claimItems[i].items_page.items[y].name
                claimItem.type = type
                claimItem.updated_at = claimItems[i].items_page.items[y].updated_at
                if (Object.keys(claimItem).length !== 0) claimsList.push(claimItem)
            }
        }
        return claimsList
    },
}
