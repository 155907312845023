<template>
    <div class="notification-card" @click="cardClicked" @keydown.enter="cardClicked">
        <div class="header">
            <div class="type">
                {{ appText(notification) }}
            </div>
            <div class="name">
                {{ typeText(notification) }}
            </div>
            <div class="time">
                {{ getTimeAgo(props.notification.created_at) }}
            </div>

            <div style="width: fit-content">
                <div v-if="!props.notification.notification_opened" class="new-dot" />
                <div v-else class="invisible-dot" />
            </div>
        </div>
        <div class="header">
            <div class="name ellipsis">{{ eventTitle(notification) }}</div>
        </div>
        <div class="body ellipsis">
            {{ eventText(notification) }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useGlobalStore } from '@/stores/global'

import type { INotification } from '../interfaces/INotification'

const props = defineProps<{
    notification: INotification
}>()

const { t } = useI18n()
const router = useRouter()
const store = useGlobalStore()
const loading = ref(false)

const appText = (notification: INotification): string => {
    if (notification.app === 'monday') {
        if (notification.data?.monday_type === 'SUPPORT') {
            return 'Support'
        } else if (notification.data?.monday_type === 'ORDER') {
            return 'Delbestilling'
        }
        return 'Support'
    }
    return t(`notifications.${notification.app}.name`)
}

const typeText = (notification: INotification): string => {
    return t(`notifications.${notification.app}.${notification.type}.title`)
}

const eventText = (notification: INotification): string => {
    let facilityName = ''

    // TODO: For now we will have notifications with both semantics, but in the future when these notifications are old,
    // this can be removed to only check for notification.data.facilityId. To the same for other places as well( 11.04.2024)
    if (notification.data.facility || notification.data.facilityId) {
        const facilityId = notification.data.facility || notification.data.facilityId
        const facility = store.allFacilityNames.find((f) => f.id === facilityId)

        facilityName = facility ? facility.name : ''
    }

    if (notification.app === 'monday' && notification.data?.monday_type) {
        if (notification.type === 'status-changed' && notification.data?.case_name && notification.data?.status_new_value) {
            return t(`notifications.${notification.app}.${notification.type}.body-status-value`, {
                statusNewValue: notification.data.status_new_value,
            })
        } else if (notification.type === 'comment' && notification.data?.case_name) {
            return t(`notifications.${notification.app}.${notification.type}.body-case-name`, { caseName: notification.data.case_name })
        }
    }
    return t(`notifications.${notification.app}.${notification.type}.body`, { facilityName })
}

const eventTitle = (notification: INotification): string => {
    if (notification.app === 'monday' && notification.data?.monday_type) {
        if (notification.type === 'status-changed' && notification.data?.case_name) {
            return notification.data.case_name
        } else if (notification.type === 'comment' && notification.data?.case_name) {
            return notification.data.case_name
        }
    }

    return notification.data?.title || ''
}

const getTimeAgo = (timestamp: string): string => {
    const date = new Date(timestamp)
    const now = new Date()

    const diffTime = Math.abs(now.getTime() - date.getTime())
    const diffSeconds = Math.floor(diffTime / 1000)
    const diffMinutes = Math.floor(diffTime / (1000 * 60))
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays > 0) {
        return diffDays + t('notifications.timestamp.days')
    } else if (diffHours > 0) {
        return diffHours + t('notifications.timestamp.hours')
    } else if (diffMinutes > 0) {
        return diffMinutes + t('notifications.timestamp.minutes')
    } else {
        return diffSeconds + t('notifications.timestamp.seconds')
    }
}

const cardClicked = async () => {
    loading.value = true
    if (!props.notification.notification_seen) {
        const wsIds = [props.notification.ws_id]
        await store.setUserNotificationsAsOpened(wsIds)
    }

    const companyId = props.notification.data.companyId || props.notification.data.company
    const facilityId = props.notification.data.facilityId || props.notification.data.facility
    const workorderId = props.notification.data.workorderId || props.notification.data.workorder
    const documentId = props.notification.data.documentId || props.notification.data.document
    if (companyId !== store.currentCompany?.id) {
        await store.setCurrentCompanyAndFacility(companyId, facilityId)
    } else if (facilityId !== store.currentFacility?.id) {
        await store.setCurrentFacilityByFacilityId(facilityId)
    }
    // Navigate to the correct page
    if (props.notification.app === 'workorder') {
        router.push({
            name: 'workorder-page',
            params: { facilityId },
            query: { workorderId },
        })
    } else if (props.notification.app === 'document') {
        router.push({
            name: 'document-list',
            params: { facilityId },
            query: { documentId },
        })
    } else if (props.notification.app === 'monday' && props.notification.data.mondayBoardId) {
        router.push({
            name: 'support-page',
            params: { facilityId },
            query: { monday_id: props.notification.data.itemId },
        })
    }

    loading.value = false
}
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

.notification-card {
    border-radius: 0.625rem;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: white;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border: 1px solid #efefef;
    box-shadow: 0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.06);
    &:hover {
        background-color: $interactive-lightest;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.body {
    margin-top: 1rem;
}

.body,
.type,
.name,
.time {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    letter-spacing: 0.01em;
    text-align: left;
}

.type {
    font-weight: 800;
}

.body,
.name,
.time {
    font-weight: 400;
}

.body {
    margin-top: 1rem;
}

.new-dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: red;
    border-radius: 50%;
    position: static;
    right: 1rem;
    top: 1rem;
    margin: 0.25rem;
}

.invisible-dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: transparent;
    border-radius: 50%;
    position: static;
    right: 1rem;
    top: 1rem;
    margin: 0.25rem;
}
</style>
