<template>
    <div id="company-picker">
        <div id="company-picker-search">
            <MCTextInput
                v-model="companySearch"
                :placeholder="t('common.search')"
                autofocus
                iconPlacement="left"
                icon="search"
                size="medium"
                @keyup.escape="showCompanyPicker = false"
            />
        </div>
        <div id="company-picker-companies">
            <div
                v-for="company in searchedCompanyList"
                :key="company.id"
                class="company-picker-box"
                role="button"
                tabindex="0"
                @click="goToCompany(company)"
                @keyup.space="goToCompany(company)"
                @keyup.enter="goToCompany(company)"
            >
                {{ company.name }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Fuse from 'fuse.js'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useGlobalStore } from '@/stores/global'
import authApi from '@/api/auth'

import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'

import router from '@/router'

const emit = defineEmits(['closeMenu'])

// Standard
const { t } = useI18n()
const store = useGlobalStore()

// reactive variables
const showCompanyPicker = ref<boolean>(true)
const companySearch = ref<string>('')

const searchOptions = {
    includeScore: true,
    shouldSort: true,
    keys: ['name'],
}

const searchedCompanyList = computed(() => {
    const companyList = store.companyList

    if (companySearch.value.trim() === '') {
        return companyList
    }
    // fuzzy search setup
    const fuse = new Fuse(companyList, searchOptions)
    const searchResults = fuse.search(companySearch.value)

    return searchResults.map((result) => result.item)
})

const goToCompany = async (company: { id: string; name: string }) => {
    const companyId = company.id as UUIDv4
    await loadCompanyContext(companyId)
    router.push({ name: 'company-facilities', params: { companyId: company.id } })

    // hide company picker
    showCompanyPicker.value = false

    // reset search
    companySearch.value = ''

    // emit event to close menu
    emit('closeMenu')
}

const loadCompanyContext = async (companyId: UUIDv4) => {
    if (!companyId) {
        console.error('Could not get companyId')
        return
    }

    const context = await authApi.companyContext({ companyId })

    if (!context) {
        console.error('Could not establish company context')
        return
    }

    store.setCurrentCompanyAndFacility(context.companyId, context.facilityId)
}
</script>

<style lang="scss">
@import '@/scss/colors.scss';

#company-picker {
    position: absolute;
    top: 17rem;
    left: 5rem;
    height: 21.875rem;
    z-index: 10003;

    display: flex;
    width: 15.1875rem;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 0.375rem;
    background: #fff;
    box-shadow: 0.125rem 0.25rem 0.5rem 0rem rgba(0, 0, 0, 0.1);

    #company-picker-search {
        .mc-input-parent {
            border: none;
            box-shadow: none;
        }
    }

    #company-picker-companies {
        border-top: 0.0625rem solid #ccc;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100% - 2.125rem);
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;

        .company-picker-box {
            padding: 0.625rem 0.75rem;
            width: 13.125rem;
            // ellipsis
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            color: var(--mLINK-neutrals-gray8, #22272a);

            /* $dds-font/body/sans-02 */
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            cursor: pointer;

            &:hover {
                background: $interactive-lightest;
            }

            &:active {
                background: $interactive-lighter;
            }
        }
    }
}
</style>
