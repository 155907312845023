<template>
    <div class="logout-container">
        <div class="auth-forms-container">
            <h2 class="auth-title">
                {{ t('auth.logout-success') }}
            </h2>
            <p class="body-body">
                {{ t('auth.logout-message') }}
            </p>
            <MCButton :label="t('auth.return-to-login')" @click="redirectToLogin" />
        </div>
        <LCLogoBar />
    </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { LCLogoBar } from '@/components/generic'

import { useGlobalStore } from '@/stores/global'

const { t } = useI18n()

// expose router to template
const router = useRouter()

const store = useGlobalStore()

const redirectToLogin = (): void => {
    router.push('/login')
}

// clear all cookies
const clearAllCookies = (): void => {
    const clearCookie = (name: string, path: string, domain: string) => {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=${path};domain=${domain};`
    }
    const cookies = document.cookie.split(';')

    cookies.forEach((cookie) => {
        const trimmedCookie = cookie.trim()
        const eqPos = trimmedCookie.indexOf('=')
        const name = eqPos > -1 ? trimmedCookie.substring(0, eqPos) : trimmedCookie

        clearCookie(name, '/', '')
        clearCookie(name, '/', document.domain)
        clearCookie(name, '/', `.${document.domain}`)
    })
    cookies.forEach((cookie) => {
        const name = cookie.trim().split('=')[0]
        if (name.startsWith('csrftoken') || name.startsWith('sessionid')) {
            clearCookie(name, '/', '')
            clearCookie(name, '/', document.domain)
            clearCookie(name, '/', `.${document.domain}`)
        }
    })
}
onMounted(() => {
    clearAllCookies()
    store.logout()
})
</script>

<style lang="scss">
@import '@/scss/colors.scss';
@import '@/scss/breakpoints.scss';

.logout-container {
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: $mlink-secondary;
    .auth-form-container {
        gap: 2rem;
    }
    .auth-form-container .MCButton {
        margin-top: 1.5rem;
    }
}
</style>
