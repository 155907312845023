import { format } from 'date-fns'

import { api } from './ApiSettings'
import http from './http'

import type { LocalHttpResponse, UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type {
    IChecklist,
    IMaintenanceRule,
    IPatchChecklist,
    IPutChecklist,
    IRuleChecklist,
    ITaskComment,
} from '@/modules/MAINTENANCE/checklist/interfaces/IChecklist'
import type { AxiosResponse } from 'axios'

export default {
    // Checklist
    fetchChecklistsByFacilityId: async (facilityId: UUIDv4): Promise<IChecklist[]> => {
        const url = api(`v1/facilities/${facilityId}/concepts/`)
        let response: Response | undefined
        let checklists: IChecklist[] = []
        try {
            response = await http.get(url)
            if (response.ok) {
                checklists = await response.json()
            }
        } catch (e) {
            console.error(`Failed to get checklists for ${facilityId}.`)
        }

        return checklists
    },
    createChecklist: async (concept: IChecklist): Promise<LocalHttpResponse> => {
        const url = api('v1/concepts/')
        const response: AxiosResponse = await http.post(url, JSON.stringify(concept))
        if (response?.status === 201) {
            const checklist = await response.data
            return { success: true, message: 'Checklist created', data: checklist }
        }
        return { success: false, message: 'Could not create checklist', data: null }
    },
    updateChecklist: async (checklist: IPutChecklist): Promise<AxiosResponse | null> => {
        const url = api(`v1/concepts/${checklist.id}/`)
        const errorMessage = `Failed to update checklist (concept) ${checklist.id}.`
        let response = null
        try {
            response = await http.put(url, JSON.stringify(checklist))
        } catch (e) {
            console.error(errorMessage)
        }
        return response
    },
    partialUpdateChecklist: async (checklist: IPatchChecklist): Promise<AxiosResponse | null> => {
        const url = api(`v1/concepts/${checklist.id}/`)
        const errorMessage = `Failed to partial update checklist (concept) ${checklist.id}.`
        let response = null
        try {
            response = await http.patch(url, JSON.stringify(checklist))
        } catch (e) {
            console.error(errorMessage)
        }
        return response
    },

    deleteChecklist: async (checklistId: UUIDv4): Promise<AxiosResponse> => {
        const url = api(`v1/concepts/${checklistId}/`)
        const response: AxiosResponse = await http.delete(url)
        if (response?.status === 204) {
            return response
        }
        return response
    },
    // Checklist search
    searchChecklistByFacilityIds: async (facilityIds: string[], searchText: string): Promise<any[] | undefined> => {
        const url = api('v1/maintenance/concept-search-in-facilities')
        let response: AxiosResponse | undefined
        let checklists: any[] = []
        try {
            response = await http.post(url, { facility_ids: facilityIds, search: searchText })
            if (response.status === 200) {
                checklists = await response.data
            }
        } catch (e) {
            console.error('Failed to search documents for facilities.')
        }

        return checklists
    },
    // Checklist comments
    fetchChecklistByChecklistId: async (checklistId: UUIDv4): Promise<IRuleChecklist | undefined> => {
        const url = api(`v1/checklists/${checklistId}/`)
        let response: Response | undefined
        let checklist: IRuleChecklist | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                checklist = await response.json()
                return checklist
            }
        } catch (e) {
            console.error(`Failed to get checklist for ${checklistId}.`)
            return undefined
        }
    },

    playPauseFacilityConcepts: async (
        facilityId: UUIDv4,
        active: boolean,
    ): Promise<{ success: boolean; message: string; data: IChecklist[] }> => {
        const url = api(`v1/maintenance/play-pause-facility-concepts/${facilityId}/`)
        const response: AxiosResponse = await http.post(url, JSON.stringify({ active }))
        if (response?.status === 200) {
            const concepts = await response.data
            return { success: true, message: 'Facility concepts played or paused', data: concepts }
        } else {
            return { success: false, message: 'Could not play or pause facility concepts', data: [] }
        }
    },
    createChecklistComment: async (checklistItemId: UUIDv4, comment: string, companyId: UUIDv4): Promise<AxiosResponse> => {
        const url = api(`v1/checklist/item/${checklistItemId}/comments/`)
        const response: AxiosResponse = await http.post(url, JSON.stringify({ comment, companyId }))
        if (response?.status === 200) {
            return response
        }
        return response
    },

    updateChecklistItemComment: async (checklistId: UUIDv4, comment: ITaskComment): Promise<AxiosResponse> => {
        const url = api(`v1/checklist/item/${checklistId}/comments/`)
        const response: AxiosResponse = await http.put(url, JSON.stringify({ comment }))
        if (response?.status === 200) {
            return response
        }
        return response
    },

    deleteChecklistItemComment: async (commentId: UUIDv4): Promise<AxiosResponse> => {
        // soft delete
        const url = api(`v1/checklist/${commentId}/delete-comment/`)
        const response: AxiosResponse = await http.delete(url)
        if (response?.status === 204) {
            return response
        }
        return response
    },

    // Rules
    createChecklistRule: async (rule: IMaintenanceRule): Promise<LocalHttpResponse> => {
        const url = api('v1/maintenance-rules/')
        const response: AxiosResponse = await http.post(url, JSON.stringify(rule))
        if (response?.status === 201) {
            const rule = await response.data
            return { success: true, message: 'Checklist rule created', data: rule }
        }
        return { success: false, message: 'Could not create checklist rule', data: null }
    },

    updateChecklistRule: async (rule: any): Promise<LocalHttpResponse> => {
        const url = api(`v1/maintenance-rules/${rule.id}/`)
        const errorMessage = `Failed to update maintenance rule ${rule.id}.`
        if ('date_rule' in rule && rule.date_rule == null) delete rule.date_rule
        if ('unit_rule' in rule && rule.unit_rule == null) delete rule.unit_rule
        if ('manual_rule' in rule && rule.manual_rule == null) delete rule.manual_rule
        let response = null
        try {
            if ('date_rule' in rule) {
                const formattedStart = format(rule.date_rule.start, 'yyyy-MM-dd') ?? null
                rule.date_rule.start = formattedStart
            }
            response = await http.put(url, JSON.stringify(rule))
            if (response?.status === 200) {
                return { success: true, message: 'Checklist rule updated', data: rule }
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return { success: false, message: errorMessage, data: null }
    },
    SuperuserSyncDaterules: async (date: Date, checklists: UUIDv4[]): Promise<UUIDv4[] | null> => {
        const url = api('v4/superuser-sync-date-rules/')
        const errorMessage = 'Failed to update connected rules'
        let response = null
        try {
            const formattedStart = format(date, 'yyyy-MM-dd') ?? null
            const updatedRule = { checklists, date: formattedStart }
            response = await http.patch(url, JSON.stringify(updatedRule))
            if (response?.status === 200) {
                return response.data.updated_checklists
            }
        } catch (e) {
            console.error(errorMessage)
            return null
        }

        return null
    },
    deleteChecklistRule: async (rule: IMaintenanceRule): Promise<LocalHttpResponse> => {
        const url = api(`v1/maintenance-rules/${rule.id}/`)
        const errorMessage = `Failed to delete maintenance rule ${rule.id}.`
        let response = null
        try {
            response = await http.delete(url)
            if (response?.status === 200) {
                return { success: true, message: 'Checklist rule deleted', data: rule }
            }
        } catch (e) {
            console.error(errorMessage)
        }

        return { success: false, message: errorMessage, data: null }
    },

    fetchMaintenanceRulesByConceptId: async (conceptId: UUIDv4): Promise<IMaintenanceRule[] | undefined> => {
        const url = api(`v4/maintenance-rules-by-concept/${conceptId}/`)
        let response: Response | undefined
        let rules: IMaintenanceRule[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                rules = await response.json()
            }
        } catch (e) {
            console.error(`Failed to get rules for ${conceptId}.`)
            return undefined
        }

        return rules
    },
    fetchMaintenanceRule: async (ruleId: UUIDv4): Promise<IMaintenanceRule | undefined> => {
        const url = api(`v1/maintenance-rules/${ruleId}/`)
        let response: Response | undefined
        let rule: IMaintenanceRule | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                rule = await response.json()
                return rule
            }
        } catch (e) {
            console.error(`Failed to get rule for ${ruleId}.`)
            return undefined
        }
    },
    searchRulesByFacilityIds: async (facilityIds: UUIDv4[], searchText: any): Promise<any[] | undefined> => {
        const url = api('v1/maintenance-rule/rule-search-in-facilities')
        let response: AxiosResponse | undefined
        let rules: any[] = []
        try {
            response = await http.post(url, { facility_ids: facilityIds, search: searchText })
            if (response.status === 200) {
                rules = await response.data
            }
        } catch (e) {
            console.error('Failed to search documents for facilities.')
        }

        return rules
    },
}
