import type { ISupportCaseStatus, ISupportCaseType } from './interfaces/ISupport'

import { i18n } from '@/i18n.js'

// @ts-expect-error-next-line
const t = i18n.global.t

export default {
    // Confluence article about how after market suspects the filter to work.
    // We therefore need to mask the names to match their wishes
    translateSupportCaseStatus: (status: ISupportCaseStatus | null, supportType: ISupportCaseType): string => {
        // Override is used to show the status as waiting for customer if the status is stuck on claims/support
        if (status?.toLocaleLowerCase() === 'stuck' && supportType === 'claim') {
            return t('support.statuses.waiting_for_customer')
        }
        switch (status?.toLocaleLowerCase()) {
            case 'avtale aktiv':
                return t('support.statuses.active')
            case 'waiting for invoice':
                return t('support.statuses.working_on_it')
            case 'in transit':
                return t('support.statuses.in_transit')
            case 'ready for invoicing':
                return t('support.statuses.working_on_it')
            case 'cancelled':
                return t('support.statuses.cancelled')
            case 'waiting for customer':
                return t('support.statuses.waiting_for_customer')
            case 'waiting for supplier':
                return t('support.statuses.waiting_for_supplier')
            case 'during production':
                return t('support.statuses.during_production')
            case 'done':
                return t('support.statuses.done')
            case 'offer expired':
                return t('support.statuses.offer_expired')
            case 'stuck':
                return t('support.statuses.offer_expired')
            case 'working on it':
                return t('support.statuses.working_on_it')
            case 'missing information':
                return t('support.statuses.missing_information')
            default:
                return t('support.statuses.no_status')
        }
    },
}
